import Api from "../Api"


const getOpponentVariants = async function(searchStr) {
    return await Api.post(`/games/getUsersAsOpponent`, {SearchString: searchStr})
}

const makeInvitation = async function(whoinvitedNickname, whoInvitedID, gameName) {
  return await Api.post(`/games/makeInvitationToPlay`, {WhoInvitedNickname: whoinvitedNickname, WhoInvitedID: whoInvitedID, GameName: gameName})
}

const GameService = {
    getOpponentVariants,
    makeInvitation,
  };
  
export default GameService;