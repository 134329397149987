import {
    atom, useRecoilState,
} from 'recoil';


export const SiteWSOpened = atom({
    key: 'SiteWSOpened',
    default: {
        opened: false,
    },
})

export const SiteWS = atom({
    key: 'SiteWS',
    default: {
        ws: null,
    },
})

export const SiteWSRestartTimeout = atom({
    key: 'SiteWSRestartTimeout',
    default: {
        timeout: 0,
    },
})

export const AllExperience = atom({
    key: 'AllExperience',
    default: {
        Level: -1,
        Experience: -1,
        EdgeExperience: -1, 
    },
})

export const AddPointsNumber = atom({
    key: 'AddPointsNumber',
    default: {
        Points: 0, 
    },
})

export const UnviewedInvitations = atom({
    key: 'UnviewedInvitations',
    default: {
        Invitations: [],
    }
})