
import ReactGodot from "../../react-godot/GodotReact.tsx"
import {requestFullScreen} from "../../utils/CrossBrowserUtils.js"
import { Box, Grid, Button } from '@mui/material';
import {FormattedMessage} from "react-intl";




const BlocksChallenge = () => {
    // let authToken = localStorage.getItem('AuthToken');
    // if (authToken === null) {
    //     location.href = "/games"
    // }


    const GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/BlocksChallenge/BlocksChallenge","experimentalVK":false,"fileSizes":{"BlocksChallenge.mcs.gz":652832,"BlocksChallenge.gz":13790961},"focusCanvas":true,"gdnativeLibs":[], 
        "mainPack": "/games_content/BlocksChallenge/BlocksChallenge.mcs.gz",
        "extraPacks": []};
    requestFullScreen()

    const closeMobileGame = ()=>{
        location.href = "/games"
    }
    return (<div key={"godotplaydiv"} style={{position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000"}}><Button size="small" variant="outlined" style={{position:"fixed", top: "7px", right: "7px", zIndex: 999999, display: 'inline-block', padding:0, minHeight: 0, minWidth: 0,}} onClick={closeMobileGame}><FormattedMessage id="exit_from_game"/></Button>
        <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} is_gz={true}  loadingBanner={"/games_content/LoadingBanners/BlocksChallenge.png"} />
    </div>)

}

export default BlocksChallenge;