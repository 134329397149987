import { Button, Modal, Container, Stack, Typography, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import React, {useState, useEffect} from 'react';
import Rate from './Rate/Rate';
import { FormattedMessage } from 'react-intl';
import {daysOrTime, daysTill} from "../utils/DateUtils";
import ModalStyle from '../utils/ModalStyle/ModalStyle';
import ModalClose from '../utils/ModalStyle/ModalClose';
import GetPaidModal from './GetPaidModal';
import {UserInfoAtom} from "../user/atoms/UserInfo"
import {useRecoilState,} from 'recoil';
import {useParams,} from "react-router-dom";
import SubscriptionsService from "../services/subscriptions/SubscriptionsService"
import { SuccessMessage,} from '../constants';

const Subscription = (props) => {
	const [isOpenGetMonth, setIsOpenGetMonth] = useState(false)
	const [failedToPay, setFailedToPay] = useState(false)
	const [successPaied, setSuccessPaied] = useState(false)
	const [cantCreatePaymentAlreadyHaveManyDays, setCantCreatePaymentAlreadyHaveManyDays] = useState(false)

	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

	let { order_id } = useParams();

	useEffect(() => {
		if (props.type == "success") {
			SubscriptionsService.checkPaymentForMonth(order_id).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setSuccessPaied(true)
					setUserInfo({UserInfo: {...userInfo, AccountType: response.data.Info.AccountType, AccountTypeTil: response.data.Info.AccountTypeTil, SubscriptionProlongation: response.data.Info.SubscriptionProlongation}})
				}
			})
		}
		if (props.type == "failure") {
			setFailedToPay(true)
		}
	}, [])


	const getMonthOfPremium = ()=>{
		if (userInfo !== null && userInfo.AccountType == "premium" && daysTill(userInfo !== null ? userInfo.AccountTypeTil : "") > 32) {
			setCantCreatePaymentAlreadyHaveManyDays(true)
			return
		}
		setIsOpenGetMonth(true)
	}
	const handleCloseGetMonth = ()=>{
		setIsOpenGetMonth(false)
	}
	const onClickTurnOffProlongation = ()=>{
		SubscriptionsService.turnOffProlongation().then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setUserInfo({UserInfo: {...userInfo, SubscriptionProlongation: false}})
			}
		})
	}

	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="md">
				<Stack 
					spacing={4} 
					paddingY={3}
					alignItems="center"
				>	
					<Typography variant='h4'> 
						<FormattedMessage id="subscription" />
					</Typography>	

					<Stack 						
						direction={{xs: 'column', md: 'row'}}
						spacing={{xs: 4, md: 4}} 
						marginY={4}
						alignItems='flex-start'
					>	
						<Rate
							rateTirle="Стандартный"
							features={[
								<FormattedMessage id="standart_max_room_size" />,
								<FormattedMessage id="standart_cant_create_communities" />,
							]}
							current={userInfo !== null && userInfo.AccountType == "standart"}
						/>

						<Rate
							rateTirle="Premium"
							features={[
								<FormattedMessage id="premium_create_big_rooms" />,
								<FormattedMessage id="premium_can_create_communities" />,
							]}
							haveProlongation={userInfo !== null && userInfo.AccountType == "premium" && userInfo.SubscriptionProlongation}
							onClick={getMonthOfPremium}
							onClickTurnOffProlongation={onClickTurnOffProlongation}
							actionText={<FormattedMessage id="premium_buy_button_text" />}
							callToActionText={<FormattedMessage id="premium_call_to_action_text" />}
							current={userInfo !== null && userInfo.AccountType == "premium"}
							daysLeft={daysOrTime(userInfo !== null ? userInfo.AccountTypeTil : "")}
						/>
					</Stack>
				</Stack>
			</Container>

			<Modal
				open={isOpenGetMonth}
				onClose={handleCloseGetMonth}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...ModalStyle, width: 370, }}>
					<ModalClose onClose={handleCloseGetMonth}/>
					<GetPaidModal />
				</Box>
			</Modal>
			
			<Snackbar open={failedToPay} 
				onClose={()=>{setFailedToPay(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"failedToPay"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"failedToPay"}>
				  <FormattedMessage id="failed_to_pay" />
  				</Alert>
			</Snackbar>	

			<Snackbar open={successPaied} 
				onClose={()=>{setSuccessPaied(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"successPaied"}>
  				<Alert  severity="success" sx={{ width: '100%' }} key={"successPaied"}>
				  <FormattedMessage id="success_paied_30" />
  				</Alert>
			</Snackbar>
			<Snackbar open={cantCreatePaymentAlreadyHaveManyDays} 
				onClose={()=>{setCantCreatePaymentAlreadyHaveManyDays(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"cantCreatePaymentAlreadyHaveManyDays"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"cantCreatePaymentAlreadyHaveManyDays"}>
				  <FormattedMessage id="cant_create_payment_already_have_many_days" />
  				</Alert>
			</Snackbar>													
		</Box>
	)
};

export default Subscription;
