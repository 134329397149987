import { Box, Stack, Tabs, Tab, Grid, Typography, Paper, Button, Card } from '@mui/material';
import TournamentPlayers from "./TournamentPlayers"
import Brackets from "./Brackets"
import React, { useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import TournamentsService from "../services/tournaments/TournamentsService"
import { SuccessMessage } from '../constants';
import Regulations from "./Regulations"
import {Authorized,} from "../user/atoms/Auth"
import {useRecoilState,} from 'recoil';

const carouselResponsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 900 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 900, min: 0 },
      items: 1
    }
  };

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`ratings-tabpanel-${index}`}
        aria-labelledby={`ratings-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `ratings-tab-${index}`,
      'aria-controls': `ratings-tabpanel-${index}`,
    };
  }



function TournamentItem(props) {
    var meChoosed = false
    if (props.choosedIndex == props.myIndex) {
        meChoosed = true
    }
    var icon = props.item.Icon
    if (props.item.Icon == "") {
        if (props.item.GameIDs.length == 1) {
            switch (props.item.GameIDs[0]) {
                case 2:
                    icon = "games_content/SlyBalabama/SlyBalabama.icon.png"
                    break
                case 3:
                    icon = "games_content/BlocksChallenge/BlocksChallenge.icon.png"
                    break
            }
        }
    }
    return (
        <Card  style={{display:"table", overflow: "hidden", width: "400px", height: "70px", border: meChoosed ? "2px solid green" : undefined}}>
            <div style={{display: "table-cell", verticalAlign: "middle", width: "70px"}}><img src={icon} style={{display: "table-cell", verticalAlign: "middle", height: "70px", align: "left"}}/></div>
            <div style={{display: "table-cell", verticalAlign: "middle", align: "left"}}><FormattedMessage id={props.item.Name} /></div>
        </Card>
    )
}

const Tournaments = () => {
    const [tabValue, setTabValue] = useState(0);
    const [tournamentIndex, setTournamentIndex] = useState(0);
    const [tournaments, setTournaments] = useState([]);

    const [authorized, setAuthorized] = useRecoilState(Authorized);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

    const afterTournamentsCarouselChange = function(previousSlide, { currentSlide, onMove }) {
        setTournamentIndex(currentSlide % tournaments.length)
        //console.log("ASASAS currentSlide = "+currentSlide, previousSlide)
    }


    useEffect(() => {
        if (authorized.Authorized) {
            TournamentsService.getTournaments().then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    if (response.data.Tournaments.length < carouselResponsive.desktop.items) {
                        carouselResponsive.desktop.items = response.data.Tournaments.length
                    }
                    setTournaments(response.data.Tournaments)
                }
            })
        } else {
            setTournaments(everyDayTournaments)
        }

    }, [authorized.Authorized])
    var is_mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        is_mobile = true
    }
    var hasBrackets = tournaments[tournamentIndex] && tournaments[tournamentIndex].HasBrackets
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                paddingY: 2,
            }}
        >
            <Stack spacing={2}>
                <div style={{width: "100%"}}>
                    <Carousel centerMode={!is_mobile} itemClass={"carouselItem"} partialVisible={false} focusOnSelect={true}  afterChange={afterTournamentsCarouselChange} swipeable={true} draggable={false} showDots={tournaments.length > 3} responsive={carouselResponsive} infinite={false} containerClass="carousel-container">
                        {
                            tournaments.map( (item, i) => <TournamentItem key={i} item={item} myIndex={i} choosedIndex={tournamentIndex} /> )
                        }
                    </Carousel>
                </div>
                <div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{width: "100%"}}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                            <Tab label={hasBrackets ? <FormattedMessage id="brackets" /> : <FormattedMessage id="players" />} {...a11yProps(hasBrackets ? 1 : 0)} />
                            <Tab label={hasBrackets ? <FormattedMessage id="players" /> : <FormattedMessage id="brackets" />} {...a11yProps(hasBrackets ? 0 : 1)} />
                            <Tab label={<FormattedMessage id="regulations" />} {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={hasBrackets ? 1 : 0}>
                    {authorized.Authorized ?
                        <TournamentPlayers tournament={tournaments[tournamentIndex]}></TournamentPlayers>                            
                        : 
                        <div style={{margin: "30px"}}>
                            <Typography variant='h5'><FormattedMessage id="authorize_to_watch_tournament" /></Typography>
                        </div>
                    }
                    </TabPanel>
                    <TabPanel value={tabValue} index={hasBrackets ? 0 : 1}>
                    {authorized.Authorized ?
                        <Brackets tournament={tournaments[tournamentIndex]}></Brackets>  
                        : 
                        <div style={{margin: "30px"}}>
                            <Typography variant='h5'><FormattedMessage id="authorize_to_watch_tournament" /></Typography>
                        </div>
                    }                         
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                    {authorized.Authorized ?
                        <Regulations tournament={tournaments[tournamentIndex]}></Regulations>                            
                        : 
                        <div style={{margin: "30px"}}>
                            <Typography variant='h5'><FormattedMessage id="authorize_to_watch_tournament" /></Typography>
                        </div>
                    }      
                    </TabPanel>
                </div>
            </Stack>
        </Box>
    )
}

export default Tournaments;

const everyDayTournaments = [
    {
        "ID": 47,
        "Name": "everyday_sly_balabama",
        "GameIDs": [
            2
        ],
        "Format": "olimpic",
        "TimeStart": "2024-09-13T17:30:00Z",
        "Icon": "",
        "Opened": true,
        "CreatorID": 0,
        "SignInStart": "2024-09-13T17:15:00Z",
        "SignInEnds": "2024-09-13T17:29:59Z",
        "HasBrackets": false
    },
    {
        "ID": 48,
        "Name": "everyday_blocks_challenge",
        "GameIDs": [
            3
        ],
        "Format": "olimpic",
        "TimeStart": "2024-09-13T18:00:00Z",
        "Icon": "",
        "Opened": true,
        "CreatorID": 0,
        "SignInStart": "2024-09-13T17:45:00Z",
        "SignInEnds": "2024-09-13T17:59:59Z",
        "HasBrackets": false
    }
]