import {
    atom,
} from 'recoil';


export const UserInfoAtom = atom({
    key: 'UserInfoAtom',
    default: null,
})

export const NickNameModal = atom({
    key: 'NickNameModal',
    default: {
        Open: false
    },
})

export const UserPersonalAtom = atom({
    key: 'UserPersonalAtom',
    default: null,
})

export const ThemeAtom = atom({
    key: 'ThemeAtom',
    default: {
        theme: "default",
    },
})

export const UserAvatarSuffix = atom({
    key: 'UserAvatarSuffix',
    default: "fsdhfkss"
})