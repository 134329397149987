import {
    atom,
} from 'recoil';


export const Authorized = atom({
    key: 'Authorized',
    default: {
        Authorized: false,
    },
})