import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, Modal, Snackbar, Alert, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { createContext, useContext, useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import "./navbar.scss";
import { useTheme } from '@emotion/react';
import {useNavigate, useLocation} from "react-router-dom"
import {useRecoilState,} from 'recoil';
import ColorModeContext from '../utils/ColorModeContext';
import { Link, NavLink } from "react-router-dom";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import {Authorized} from "../user/atoms/Auth"
import {UserInfoAtom, NickNameModal, UserPersonalAtom, ThemeAtom} from "../user/atoms/UserInfo"
import ModalStyle from '../utils/ModalStyle/ModalStyle';
import {ModalStyleLG} from '../utils/ModalStyle/ModalStyle';
import Auth from "../user/auth/Auth"
import SetNickNameModalPage from "./SetNickNameModalPage"
import UserService from "../services/user/UserService"
import { SuccessMessage, } from '../constants';
import {domainNameAPI, protocolAPI, apiPort} from "../environment"
import {makeString} from "../utils/common"
import {OpenAuth} from "./atoms"

const pages = [
    {
      title: <FormattedMessage id="games" />,
      url: '/games',
      metric: "games"
    }, 
    {
      title: <FormattedMessage id="leaderboards" />,
      url: '/leaderboards',
      metric: "leaderboards"
    }, 
    // {
    //   title: <FormattedMessage id="subscription" />,
    //   url: '/subscription',
    //   metric: "subscription"
    // },
    {
      title: <FormattedMessage id="tournaments" />,
      url: '/tournaments',
      metric: "tournaments"
    }, 
]

const userPages = [
    {
      title: <FormattedMessage id="profile" />,
      url: '/profile',
      metric: "profile"
    }, 
]


const Navbar = ({ openModal, home, inTheRoom, audioBoxes }) => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorUserNav, setAnchorUserNav] = useState(null);
    const [authIndex, setAuthIndex] = useState(0)
    const navigate = useNavigate();

    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authOpen, setAuthOpen] = useRecoilState(OpenAuth)
    const [nickNameModal, setNickNameModal] = useRecoilState(NickNameModal)
    const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
    const [innerTheme, _] = useRecoilState(ThemeAtom);

    let apiUrl = protocolAPI+domainNameAPI+apiPort

    const handleOpenLogin = () => {
        setAuthIndex(0)
        setAuthOpen(true);
    };
    const handleOpenRegister = () => {
        setAuthIndex(1)
        setAuthOpen(true);
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserNavMenu = ()=>{
        setAnchorUserNav(null)
    }
    const openUserNavMenu = (event)=>{
        setAnchorUserNav(event.currentTarget);
    }

    const handleClose = () => {
      setAuthOpen(false);
    };

    const handleNickNameClose = ()=>{
      if (userInfo.Nickname != null) {
        setNickNameModal({Open: false})
      }
    }
    var userAvatarSuffix = makeString(5)
    let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
    if (userInfo && userInfo != null && userInfo.IconUrl !== "") {
        avaSrc=apiUrl+"/mkstatic/shared/avatars/"+userInfo.IconUrl+"?dop"+userAvatarSuffix
    }

    useEffect(() => {
      if (authorized.Authorized && userPersonal == null) {
        UserService.getUserPersonal().then((response)=>{
          if (response.data.Success == SuccessMessage) {
            setUserPersonal(response.data.UserPersonal)
          }
        })
      }
      if (authorized.Authorized && userInfo === null) {
        UserService.getUserInfo().then(response=>{
            if (response.data.Success == SuccessMessage) {
                if (response.data.UserInfo.Nickname === null) {
                  setNickNameModal({Open: true})
                } else {
                  localStorage.setItem("my_nickname", response.data.UserInfo.Nickname);
                }
                if (!(response.data.UserInfo.ShowedState && response.data.UserInfo.ShowedState.ShowRegisterGiftSubscription)) {
                  // setShowRegisterGiftSubscription({Open: true})
                }
                if (response.data.UserInfo.AccountStatus == "banned") {
                  navigate("/banned")
                }
                setUserInfo(response.data.UserInfo)
            }
        }).catch((err) => {
          console.log("Get User Info: ", err)
        })
      }
    }, [authorized]);
    return (
        <div>
          <AppBar 
                position="static" 
                className={`navbar ${home ? 'navbar--home': ''}`}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={()=>{navigate("/")}}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            cursor: "pointer",
                            pt: "9px",
                        }}
                    >
                        {userInfo && userInfo.AccountType != "standart" ? 
                            <span>{userInfo.AccountType}<img src="/logos/logo_prem.png" style={{height: "50px"}}/></span> :
                            <span><img src="/logos/logo.png" style={{height: "50px"}}/></span>}
                    </Typography>
  
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                        display: { xs: 'block', md: 'none' },
                        color: 'inherit',
                        }}
                    >
                        {pages.map((page, index) => (
                        <MenuItem key={index} 
                            onClick={()=>{
                            navigate(page.url)
                            handleCloseNavMenu()
                            }}>
                            <NavLink
                            to={page.url}
                            style={{
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                            onClick={()=>{
                            }}
                            >
                            {page.title}
                            </NavLink>
                        </MenuItem>
                        ))}
                    </Menu>
                    </Box>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        {userInfo && userInfo.AccountType != "standart" ?
                            <span><img src="/logos/logo_prem.png" style={{height: "50px"}}/></span> :
                            <span><img src="/logos/logo.png" style={{height: "50px"}}/></span>}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page, index) => (
                            <NavLink
                              to={page.url}
                              className="navbar__nav-link"
                              key={index}
                              onClick={()=>{
                              }}
                            >
                            {page.title}
                            </NavLink>
                        ))}
                    </Box>
  
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton color="inherit" onClick={()=>{
                colorMode.toggleColorMode()}}>
                {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>     
  
              {authorized.Authorized ? 
                null:
                    <Button variant="outlined" color="inherit" onClick={handleOpenLogin} size="small">
                        <FormattedMessage id="login" />
                    </Button>
              }
  
              {authorized.Authorized ? 
                null
                :
                    <Button variant="outlined" color="inherit" onClick={handleOpenRegister} size="small">
                        <FormattedMessage id="registration" />
                    </Button>
              }
  
              {authorized.Authorized ?
                  <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                    <span style={userInfo && userInfo.AccountType != "standart" ? {border: "2px solid #FFD700", borderRadius: "30px"} : {}}> 
                        <Avatar onClick={openUserNavMenu} alt="Remy Sharp" src={avaSrc} style={{cursor: "pointer"}}/>
                    </span>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorUserNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorUserNav)}
                      onClose={handleCloseUserNavMenu}
                      sx={{
                        display: { xs: 'block', },
                        color: 'inherit',
                      }}
                    >
                      {userPages.map((page, index) => (
                        <MenuItem key={index} onClick={()=>{
                          navigate(page.url)
                          handleCloseUserNavMenu()
                        }}>
                          <NavLink
                            to={page.url}
                            style={{
                              color: 'inherit',
                              textDecoration: 'none',
                            }}
                            onClick={()=>{
                            }}
                          >
                            {page.title}
                          </NavLink>
                        </MenuItem>
                      ))}
                    </Menu>                  
                  </Box>
                : null
              }
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>


      <Modal
        open={authOpen}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyleLG, width: 420 }}>
          <Auth startIndex={authIndex} onClose={handleClose}/>
        </Box>
      </Modal>
      <Modal
          open={nickNameModal.Open}
          onClose={handleNickNameClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400 }}>
          <SetNickNameModalPage/>
        </Box>
      </Modal>   

      </div>
    )
}

export default Navbar;