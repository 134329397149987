import { Button, Grid, Typography, Checkbox, FormControlLabel, Snackbar, Alert, Modal, Box } from '@mui/material';
import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import UserService from "../services/user/UserService"
import {UserInfoAtom} from "../user/atoms/UserInfo"
import {useRecoilState,} from 'recoil';
import { SuccessMessage,} from '../constants';
import SubscriptionsService from "../services/subscriptions/SubscriptionsService"
import {domainNameAPI} from "../environment"
import NeedPhoneEmail from "./NeedPhoneEmail"
import ModalStyle from '../utils/ModalStyle/ModalStyle';
import ModalClose from '../utils/ModalStyle/ModalClose';




const GetPaidModal = () => {
    const [autoProlongate, setAutoProlongate] = useState(false)
	const [cantCreatePayment, setCantCreatePayment] = useState(false)
	const [cantCreatePaymentAlreadyHaveManyDays, setCantCreatePaymentAlreadyHaveManyDays] = useState(false)
	const [haveSubscriptionProlongation, setHaveSubscriptionProlongation] = useState(false)
	const [needPhoneEmail, setNeedPhoneEmail] = useState(false)

    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

    const switchAutoProlongate = (event)=>{
        setAutoProlongate(event.target.checked);
    }
    const getPaid = ()=>{
        // UserService.buyPremiumMonth().then((response)=>{
		// 	if (response.data.Success == SuccessMessage) {
		// 		setUserInfo({UserInfo: {...userInfo.UserInfo, AccountType: "premium", AccountTypeTil: response.data.NewAccount.NewAccountTypeTil}})
		// 	}
		// })
		SubscriptionsService.createPaymentForMonth(domainNameAPI, autoProlongate).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				if (response.data.Result.Success) {
					window.location.href = response.data.Result.PaymentURL;
				} else {

				}
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 2) {
				setCantCreatePaymentAlreadyHaveManyDays(true)
			}
			if (err.response.data.ErrorCode == 3) {
				setHaveSubscriptionProlongation(true)
			}
			if (err.response.data.ErrorCode == 4) {
				setNeedPhoneEmail(true)
			}						
		})
    }
	const handleCloseNeedPhoneEmail = ()=>{
		setNeedPhoneEmail(false)
	}
	const continueToMakePaymentPhoneEmail = ()=>{
		handleCloseNeedPhoneEmail()
		getPaid()
	}
    return (
		<Grid
			container
			rowSpacing={4}
			spacing={{ xs: 2, sm: 4 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
            justifyContent="center"
            alignItems="center"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="month_of_premium" />
				</Typography>
			</Grid> 

			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h6'>
					<FormattedMessage id="so_to_pay" />
				</Typography>
			</Grid>

        
			{false && <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
                justifyContent="flex-start"
                alignItems="flex-start"
			>
				<Grid item xs={4} sm={8} md={12}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox key={1} checked={autoProlongate} onChange={switchAutoProlongate}/>
                    }
                        label={
                            <Typography variant='body1'>
                                <FormattedMessage id="auto_prolongate" />
                            </Typography>
                        }
                        labelPlacement="end"
                        />
				</Grid>
			</Grid>}

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={getPaid}>
					<FormattedMessage id="get_paid" />
				</Button>
			</Grid>


			<Modal
				open={needPhoneEmail}
				onClose={handleCloseNeedPhoneEmail}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...ModalStyle, width: 370, }}>
					<ModalClose onClose={handleCloseNeedPhoneEmail}/>
					<NeedPhoneEmail continueToMakePayment={continueToMakePaymentPhoneEmail}/>
				</Box>
			</Modal>

			<Snackbar open={cantCreatePayment} 
				onClose={()=>{setCantCreatePayment(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"cantCreatePayment"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"cantCreatePayment"}>
				  <FormattedMessage id="cant_create_payment" />
  				</Alert>
			</Snackbar>
			<Snackbar open={cantCreatePaymentAlreadyHaveManyDays} 
				onClose={()=>{setCantCreatePaymentAlreadyHaveManyDays(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"cantCreatePaymentAlreadyHaveManyDays"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"cantCreatePaymentAlreadyHaveManyDays"}>
				  <FormattedMessage id="cant_create_payment_already_have_many_days" />
  				</Alert>
			</Snackbar>	
			<Snackbar open={haveSubscriptionProlongation} 
				onClose={()=>{setHaveSubscriptionProlongation(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"haveSubscriptionProlongation"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"haveSubscriptionProlongation"}>
				  <FormattedMessage id="you_have_subusciption_prolongation" />
  				</Alert>
			</Snackbar>									            
        </Grid>
    )
}


export default GetPaidModal;
