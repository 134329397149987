import Api from "../Api"


const register = async function(nickname, email, password, birthDay) {
    return await Api.post(`/auth/register`, {Nickname: nickname, Email: email, Password: password, Birthday: birthDay,})
}

const loginPassword = async function(email, password) {
    return await Api.post(`/auth/loginPassword`, {Email: email, Password: password})
}

const loginCheckEmail = async function(token) {
    return await Api.post(`/auth/loginCheckEmailToken`, {Token: token})
}

const refreshToken = async function(refreshToken) {
    return await Api.post(`/auth/refreshToken`, {RefreshToken: refreshToken})
}

const checkNickname = async function(nickname) {
    return await Api.post(`/auth/check_nickname`, {Nickname: nickname})
}

const registerLoginVK = async function(code) {
    return await Api.post(`/auth/registerLoginVK`, {Code: code})
}

const recoverPassword = async function(email, password) {
    return await Api.post(`/auth/recoverPassword`, {Email: email, Password: password})
}

const setNewPassword = async function(token, password) {
    return await Api.post(`/auth/setNewPassword`, {Token: token, NewPassword: password})
}

const AuthService = {
    loginPassword,
    refreshToken,
    loginCheckEmail,
    register,
    checkNickname,
    registerLoginVK,
    recoverPassword,
    setNewPassword,
  };
  
export default AuthService;