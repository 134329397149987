
import { Box, Grid, } from '@mui/material';
import ReactGodot from "../../react-godot/GodotReact.tsx"
import { ErrorBoundary } from 'react-error-boundary'
import InvitePanel from "../InvitePanel.jsx"
import {useParams, useNavigate} from "react-router-dom";
import {useRecoilState,} from 'recoil';
import {PlayMobile} from "../atoms/Common.js"
import {isMobile} from "../../utils/common.js"
import React, { useMemo, useState, useEffect, useRef } from 'react';

const GameName = "Space1vs1v1"

const Space1vs1v1 = () => {
    const GODOT_CONFIG = {"args":[],"canvasResizePolicy": isMobile ? 2 : 1,"executable":"/games_content/Space1vs1v1/Space1vs1v1","experimentalVK":false,"fileSizes":{"Space1vs1v1.pck":4295248,"Space1vs1v1.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};
    //const GODOT_CONFIG = {"args":[],"canvasResizePolicy":1,"executable":"games/DodgeTheCreeps/Dodge the Creeps","experimentalVK":false,"fileSizes":{"Dodge the Creeps.pck":2066608,"Dodge the Creeps.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};

    const [playMobile, setPlayMobile] = useRecoilState(PlayMobile)
    //const [keyForGodot, setKeyForGodot] = useState(1)


    let { inviting } = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if (inviting) {
            setPlayMobile(true)
        }
    }, [inviting])

    const playWithOpponent = ()=>{
        setPlayMobile(true)
    }
    const justPlay = ()=>{
        setPlayMobile(true)
    }
    const closeMobileGame = ()=>{
        location.href = "/game_page/"+GameName
    }
    
    if (playMobile) {
        return (<div key={"godotplaydiv"} style={{position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000"}}><div style={{position:"fixed", top: "7px", right: "7px", zIndex: 999999}} onClick={closeMobileGame}><img style={{width: "20px",}} src="/controls/close.png"/></div><ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} /></div>)
    }

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <Grid
                container
                columns={{ xs: 4, sm: 4, md: 12}}
                justifyContent="space-between"
                alignItems="center"
                sx={{pt: "20px",}}
            >
				<Grid item xs={4} sm={4} md={6}>
                    <InvitePanel gameName={GameName} inviting={inviting} playWithOpponent={playWithOpponent} justPlay={justPlay}></InvitePanel>
                </Grid>
				<Grid item xs={4} sm={4} md={6}>
                        {!isMobile && <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} />}
                </Grid>                
            </Grid>
        </Box>
    );
}

export default Space1vs1v1;