
import { Snackbar, Alert, Button, } from '@mui/material';
import React, {useState, useEffect} from 'react';

import {Authorized} from "../user/atoms/Auth"
import SearchOpponentField from "./SearchOpponentField"
import {FormattedMessage} from "react-intl";
import {useRecoilState,} from 'recoil';
import GameService from "../services/games/GamesService"
import {SuccessMessage, } from "../constants";
import {isMobile} from "../utils/common.js"




const InvitePanel = (props) => {
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [choosedOption, setChoosedOption] = useState(undefined)

    const [invitationMadeShow, setInvitationMadeShow] = useState(false)
    const [invitationAlreadyMadeShow, setInvitationAlreadyMadeShow] = useState(false)
    

    const makeInvitation = ()=>{
        if (!choosedOption) {
            return
        }
        GameService.makeInvitation(choosedOption.Nickname, choosedOption.ID, props.gameName).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                console.log("ASASAS INVITED")
                setInvitationMadeShow(true)
            }
        }).catch((err)=>{
			if (err.response.data.ErrorCode == 3) {
                setInvitationAlreadyMadeShow(true)
            }
        })
    }
    const changedOption = (option)=>{
        setChoosedOption(option)
    }
    const playWithOpponent = ()=>{
        props.playWithOpponent()
    }
    const justPlay = ()=>{
        props.justPlay()
    }


    if (!authorized.Authorized) {
        return (<></>)
    }
    return (
        <>
            <SearchOpponentField gameName={props.gameName} fullScreenGame={props.fullScreenGame} changeOption={changedOption} inviting={props.inviting}></SearchOpponentField>
            <Button variant="contained" size="large" sx={{m: "10px", float: (isMobile || props.fullScreenGame) ? "center" : "left"}} onClick={makeInvitation}><FormattedMessage id="make_invitation" /></Button>{props.fullScreenGame && <br/>}
            {choosedOption && (isMobile || props.fullScreenGame) && <Button variant="contained" size="large" sx={{m: "10px"}} onClick={playWithOpponent}><FormattedMessage id="play_with_concrete_opponent" /></Button>}
            {!choosedOption && (isMobile || props.fullScreenGame) && <Button variant="contained" size="large"  sx={{m: "10px"}} onClick={justPlay}><FormattedMessage id="play" /></Button>}


            <Snackbar open={invitationMadeShow}
                onClose={() => { setInvitationMadeShow(false)}}
                autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"InvitationMadeSnackbar"}>
                <Alert severity="success" sx={{ width: '100%' }} key={"InvitationMade"} >
                    <FormattedMessage id="invitation_made" />
                </Alert>
			</Snackbar>
            <Snackbar open={invitationAlreadyMadeShow}
                onClose={() => { setInvitationAlreadyMadeShow(false)}}
                autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"InvitationMadeAlreadySnackbar"}>
                <Alert severity="success" sx={{ width: '100%' }} key={"InvitationAlreadyMade"} >
                    <FormattedMessage id="invitation_already_made" />
                </Alert>
			</Snackbar>
        </>
    )
}


export default InvitePanel;