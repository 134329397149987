import {useRecoilState,} from 'recoil';
import { Button, Checkbox, Container, FormControl, Modal, Stack, TextField, Typography, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import React, {useState, useEffect} from 'react';
import UserInfo from './user_info/UserInfo';
import {FormattedMessage} from "react-intl";
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import {UserPersonalAtom, UserInfoAtom, UserAvatarSuffix, ThemeAtom} from "../../atoms/UserInfo"
import {ShowFileTooBig, ShowChangePassword, ShowPasswordChangedAlert} from "../../atoms/Show"
import UserService from '../../../services/user/UserService';
import {SuccessMessage, MaxAboutLength, MaxCountryLength, MaxRegionLength, MaxCityLength, MaxTelephoneLength, MaxNameLength, MaxSurnameLength, DefaultLang} from "../../../constants";
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import ChangePassword from './change_password/ChangePassword';
import TokenService from "../../../services/auth/TokenService"
import { Authorized } from '../../atoms/Auth'; 
import {useNavigate} from "react-router-dom"
import {checkForMate} from "../../../utils/TextFilters"

const Profile = () => {
	const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
	const [innerTheme, _] = useRecoilState(ThemeAtom);
	const [showTooBig, setShowTooBig] = useRecoilState(ShowFileTooBig);
	const [showChangePassword, setShowChangePassword] = useRecoilState(ShowChangePassword);
    const [showPasswordChangedAlert, setShowPasswordChangedAlert] = useRecoilState(ShowPasswordChangedAlert);
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [userAvatarSuffix, setUserAvatarSuffix] = useRecoilState(UserAvatarSuffix);


	const [ava, setAva] = useState("");
	const [avaKey, setAvaKey] = useState(1);

	const [about, setAbout] = useState("");
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");
	const [telephone, setTelephone] = useState("");
	const [country, setCountry] = useState("");
	const [region, setRegion] = useState("");
	const [city, setCity] = useState("");

	const [showAbout, setShowAbout] = useState(false);
	const [showName, setShowName] = useState(false);
	const [showSurname, setShowSurname] = useState(false);
	const [showTelephone, setShowTelephone] = useState(false);
	const [showCountry, setShowCountry] = useState(false);
	const [showRegion, setShowRegion] = useState(false);
	const [showCity, setShowCity] = useState(false);

	const [aboutError, setAboutError] = useState(null);
	const [nameError, setNameError] = useState(null);
	const [surnameError, setSurnameError] = useState(null);
	const [countryError, setCountryError] = useState(null);
	const [regionError, setRegionError] = useState(null);
	const [cityError, setCityError] = useState(null);
	const [telephoneError, setTelephoneError] = useState(null);


	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");

	const [showSaved, setShowSaved] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (userPersonal != null) {
			setAbout(userPersonal.About)
			setName(userPersonal.Name)
			setSurname(userPersonal.Surname)
			setTelephone(userPersonal.Phone)
			setCountry(userPersonal.Country)
			setRegion(userPersonal.Region)
			setCity(userPersonal.City)

			setShowAbout(userPersonal.ShowAbout)
			setShowName(userPersonal.ShowName)
			setShowSurname(userPersonal.ShowSurname)
			setShowTelephone(userPersonal.ShowPhone)
			setShowCountry(userPersonal.ShowCountry)
			setShowRegion(userPersonal.ShowRegion)
			setShowCity(userPersonal.ShowCity)
		}
		if (userInfo != null) {
			setUsername(userInfo.Nickname)
			setEmail(userInfo.Email)

			let apiUrl = protocolAPI+domainNameAPI+apiPort
			let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
			if (userInfo && userInfo != null && userInfo.IconUrl !== "") {
			  avaSrc=apiUrl+"/mkstatic/shared/avatars/"+userInfo.IconUrl+"?dop"+userAvatarSuffix
			}
			setAva(avaSrc)
			setAvaKey(avaKey+1)
		}
	}, [userPersonal, userInfo]);

	const saveChanges = ()=>{
		if (about.length > MaxAboutLength) {
			setAboutError(<span><FormattedMessage id="cantbelonger"/> {MaxAboutLength}</span>)
			return
		} else {
			setAboutError(null)
		}
		if (name.length > MaxNameLength) {
			setNameError(<span><FormattedMessage id="cantbelonger"/> {MaxNameLength}</span>)
			return
		} else {
			setNameError(null)
		}
		if (surname.length > MaxSurnameLength) {
			setSurnameError(<span><FormattedMessage id="cantbelonger"/> {MaxSurnameLength}</span>)
			return
		} else {
			setSurnameError(null)
		}
		if (telephone.length > MaxTelephoneLength) {
			setTelephoneError(<span><FormattedMessage id="cantbelonger"/> {MaxTelephoneLength}</span>)
			return
		} else {
			setTelephoneError(null)
		}
		if (country.length > MaxCountryLength) {
			setCountryError(<span><FormattedMessage id="cantbelonger"/> {MaxCountryLength}</span>)
			return
		} else {
			setCountryError(null)
		}
		if (region.length > MaxRegionLength) {
			setRegionError(<span><FormattedMessage id="cantbelonger"/> {MaxRegionLength}</span>)
			return
		} else {
			setRegionError(null)
		}
		if (city.length > MaxCityLength) {
			setCityError(<span><FormattedMessage id="cantbelonger"/> {MaxCityLength}</span>)
		} else {
			setCityError(null)
		}
		UserService.setUserPersonal(about, name, surname, telephone, country, region, city, showAbout, showName, showSurname, showTelephone, showCountry, showRegion, showCity).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setShowSaved(true)
				setUserPersonal({
					...userPersonal.UserPersonal,
					About: about,
					Name: name,
					Surname: surname,
					Phone: telephone,
					Country: country,
					Region: region,
					City: city,
					ShowAbout: showAbout,
					ShowName: showName,
					ShowSurname: showSurname,
					ShowPhone: showTelephone,
					ShowCountry: showCountry,
					ShowRegion: showRegion,
					ShowCity: showCity,
				})
			}
		})
	}
	const declineChanges = ()=>{
		if (userPersonal != null) {
			setAbout(userPersonal.About)
			setName(userPersonal.Name)
			setSurname(userPersonal.Surname)
			setTelephone(userPersonal.Phone)
			setCountry(userPersonal.Country)
			setRegion(userPersonal.Region)
			setCity(userPersonal.City)

			setShowAbout(userPersonal.ShowAbout)
			setShowName(userPersonal.ShowName)
			setShowSurname(userPersonal.ShowSurname)
			setShowTelephone(userPersonal.ShowPhone)
			setShowCountry(userPersonal.ShowCountry)
			setShowRegion(userPersonal.ShowRegion)
			setShowCity(userPersonal.ShowCity)

			setAboutError(null)
			setNameError(null)
			setSurnameError(null)
			setCountryError(null)
			setRegionError(null)
			setCityError(null)
			setTelephoneError(null)
		}
	}

	const changeAbout = (event) => {
		if (event.target.value.length > MaxAboutLength) {
			setAboutError(<span><FormattedMessage id="cantbelonger"/> {MaxAboutLength}</span>)
		} else {
			setAboutError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setAboutError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setAboutError(null)
		}
		setAbout(event.target.value);
	}
	const changeName = (event) => {
		if (event.target.value.length > MaxNameLength) {
			setNameError(<span><FormattedMessage id="cantbelonger"/> {MaxNameLength}</span>)
		} else {
			setNameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setNameError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setNameError(null)
		}		
		setName(event.target.value);
	}
	const changeSurname = (event) => {
		if (event.target.value.length > MaxSurnameLength) {
			setSurnameError(<span><FormattedMessage id="cantbelonger"/> {MaxSurnameLength}</span>)
		} else {
			setSurnameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setSurnameError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setSurnameError(null)
		}			
		setSurname(event.target.value);
	}
	const changeTelephone = (event) => {
		if (event.target.value.length > MaxTelephoneLength) {
			setTelephoneError(<span><FormattedMessage id="cantbelonger"/> {MaxTelephoneLength}</span>)
		} else {
			setTelephoneError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setTelephoneError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setTelephoneError(null)
		}			
		setTelephone(event.target.value);
	}
	const changeCountry = (event) => {
		if (event.target.value.length > MaxCountryLength) {
			setCountryError(<span><FormattedMessage id="cantbelonger"/> {MaxCountryLength}</span>)
		} else {
			setCountryError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setCountryError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setCountryError(null)
		}			
		setCountry(event.target.value);
	}
	const changeRegion = (event) => {
		if (event.target.value.length > MaxRegionLength) {
			setRegionError(<span><FormattedMessage id="cantbelonger"/> {MaxRegionLength}</span>)
		} else {
			setRegionError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setRegionError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setRegionError(null)
		}			
		setRegion(event.target.value);
	}
	const changeCity = (event) => {
		if (event.target.value.length > MaxCityLength) {
			setCityError(<span><FormattedMessage id="cantbelonger"/> {MaxCityLength}</span>)
		} else {
			setCityError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setCityError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setCityError(null)
		}		
		setCity(event.target.value);
	}

	const changeShowAbout = (event) => {
		setShowAbout(event.target.checked);
	}
	const changeShowName = (event) => {
		setShowName(event.target.checked);
	}
	const changeShowSurname = (event) => {
		setShowSurname(event.target.checked);
	}
	const changeShowTelephone = (event) => {
		setShowTelephone(event.target.checked);
	}
	const changeShowCountry = (event) => {
		setShowCountry(event.target.checked);
	}
	const changeShowRegion = (event) => {
		setShowRegion(event.target.checked);
	}
	const changeShowCity = (event) => {
		setShowCity(event.target.checked);
	}


	const handleChangePasswordClose = ()=>{
		setShowChangePassword({show: false})
	}
	const doOpenChangePassword = ()=>{
		setShowChangePassword({show: true})
	}


	const logout = ()=>{
		UserService.logout(TokenService.getLocalRefreshToken(), TokenService.getDeviceKey()).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				localStorage.removeItem("TetatetSettings");
				localStorage.removeItem("AuthToken");
				localStorage.removeItem("RefreshToken");
				localStorage.removeItem("DeviceKey");
				localStorage.removeItem("my_nickname");

				setAuthorized({Authorized: false})
				setUserInfo(null)
				setUserPersonal(null)
				navigate("/")
			}
		})
	}

	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>

			<Container maxWidth="lg">
				<Stack spacing={2} paddingY={4}>
					<UserInfo
						avatar={ava}
						akey={avaKey}
						nickname={username}
						email={email}
					/>

					<Typography variant="caption" display="block" align='right'>
						<FormattedMessage id="is_visible_others" />
      				</Typography>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField
								id="about"
								value={about}
								onChange={changeAbout}
								label={<FormattedMessage id="about" />}
								multiline
								rows={4}
								helperText={aboutError}
								error= {aboutError != null}
							/>
						</FormControl>

						<Checkbox key={1} checked={showAbout} onChange={changeShowAbout}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="name" value={name} onChange={changeName} label={<FormattedMessage id="name" />} variant="outlined" 
								helperText={nameError}
								error= {nameError != null}
							/>
						</FormControl>
												
						<Checkbox key={2} checked={showName} onChange={changeShowName}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="surname" value={surname} onChange={changeSurname} label={<FormattedMessage id="surname" />} variant="outlined" 
								helperText={surnameError}
								error= {surnameError != null}							
							/>
						</FormControl>
												
						<Checkbox key={3} checked={showSurname} onChange={changeShowSurname}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="phone" value={telephone} onChange={changeTelephone} label={<FormattedMessage id="phone" />} variant="outlined" 
								helperText={telephoneError}
								error= {telephoneError != null}								
							/>
						</FormControl>
												
						<Checkbox key={4} checked={showTelephone} onChange={changeShowTelephone}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="country" value={country} onChange={changeCountry} label={<FormattedMessage id="country" />} variant="outlined" 
								helperText={countryError}
								error= {countryError != null}								
							/>
						</FormControl>
												
						<Checkbox key={5} checked={showCountry} onChange={changeShowCountry}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="region" value={region} onChange={changeRegion} label={<FormattedMessage id="region" />} variant="outlined"
								helperText={regionError}
								error= {regionError != null}								
							/>
						</FormControl>
												
						<Checkbox key={6} checked={showRegion} onChange={changeShowRegion}/>
					</Stack>

					<Stack direction="row" alignItems="flex-start" spacing={2}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="city" value={city} onChange={changeCity} label={<FormattedMessage id="city" />} variant="outlined" 
								helperText={cityError}
								error= {cityError != null}								
							/>
						</FormControl>
												
						<Checkbox key={7} checked={showCity} onChange={changeShowCity}/>
					</Stack>

	{userInfo != null && userInfo.Email !== ""  ? 
					<Box padding={1}>
						<Button variant="text" size="large" onClick={doOpenChangePassword}>
							{<FormattedMessage id="change_password" />}
						</Button>
					</Box>
	: null }

					
					<Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} paddingX={1}>						
						<Button variant="contained" size="large" onClick={saveChanges} disabled={aboutError != null || nameError != null || surnameError != null || telephoneError != null || countryError != null || regionError != null || cityError != null}>
							{<FormattedMessage id="save" />}
						</Button>
						<Button variant="outlined" size="large" onClick={declineChanges}>
							{<FormattedMessage id="cancel" />}
						</Button>
					</Stack>

					<Stack direction="row" alignItems="flex-start" justifyContent="flex-end" spacing={2} paddingX={1}>
						<Button variant="outlined" size="large" onClick={logout}>
							{<FormattedMessage id="Exit" />}
						</Button>						
					</Stack>
				</Stack>
			</Container>

			<Modal
				open={showChangePassword.show}
				onClose={handleChangePasswordClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
       			<Box sx={{ ...ModalStyle, width: 400, }}>
         			 <ChangePassword userInfo={userInfo}/>
        		</Box>
      		</Modal>

	  		<Snackbar open={showPasswordChangedAlert.show} 
				onClose={()=>{setShowPasswordChangedAlert({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
			>
  				<Alert  severity="success" sx={{ width: '100%' }} >
				  <FormattedMessage id="PasswordChanged" />
  				</Alert>
			</Snackbar>

	  		<Snackbar open={showSaved} 
				onClose={()=>{setShowSaved(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
			>
  				<Alert  severity="success" sx={{ width: '100%' }} >
				  <FormattedMessage id="Saved" />
  				</Alert>
			</Snackbar>	

			<Snackbar open={showTooBig.show} 
				onClose={()=>{setShowTooBig({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showTooBig"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showTooBig"}>
				  <FormattedMessage id="imageIsTooBigMoreThen3Mb" />
  				</Alert>
			</Snackbar>	
		</Box>
	)
};

export default Profile;