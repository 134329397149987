


export const protocolAPI = "https://"

export const influxdbORG = "minicybersport"
export const influxdbBucket = "web_ui"
export const apiPort = ":443"
export const staticAPIPort = ":443"

export const domainNameAPI = window.location.host == "dev.minicybersport.ru" ? "dev.minicybersport.ru" : "minicybersport.ru"
export const domainNameStaticAPI = window.location.host == "dev.minicybersport.ru" ? "dev.minicybersport.ru" : "minicybersport.ru"
export const vkRegisterRedirectUrl = window.location.host == "dev.minicybersport.ru" ? "https://dev.minicybersport.ru/getVKCodeToRegisterLogin" : "https://minicybersport.ru/getVKCodeToRegisterLogin"


// export const domainNameAPI = "minicybersport.ru"
// export const domainNameStaticAPI = "minicybersport.ru"
// export const vkRegisterRedirectUrl = "https://minicybersport.ru/getVKCodeToRegisterLogin"
