




export const linkToGameByID = (gameID)=>{
    switch(gameID) {
        case 2:
            return "/game_page/SlyBalabama"
        case 3:
            return "/game_page/BlocksChallenge"
    }
}