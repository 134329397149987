import {
    atom,
} from 'recoil';


export const ShowFileTooBig = atom({
    key: 'ShowFileTooBig',
    default: {
        show: false
    },
})

export const ShowChangePassword = atom({
    key: 'ShowChangePassword',
    default: {
        show: false
    },
})

export const ShowPasswordChangedAlert = atom({
    key: 'ShowPasswordChangedAlert',
    default: {
        show: false
    },
})