import {
    atom,
} from 'recoil';

export const BracketsMatches = atom({
    key: 'BracketsMatches',
    default: {
        matches: [],
    },
})
