import {
    useRecoilState,
  } from 'recoil';
import { Button, Stack, TextField, Link, Box, Typography } from '@mui/material';
import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import AuthService from '../../../services/auth/AuthService';
import {SuccessMessage, EndSaltLength, MaxEmailLength, MaxPasswordLength, VKAppID} from "../../../constants";
import {Authorized,} from '../../atoms/Auth'
import {makeString} from "../../../utils/common"
import {useNavigate, useLocation, useParams} from "react-router-dom"
import {goToVKForCode} from "../RegistrationForm/RegistrationForm"

const LogInForm = (props) => {
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [forgotPassword, setForgotPassword] = useState(false);
	const [recoverPasswordSent, setRecoverPasswordSent] = useState(false);
	const [passwordError, SetPasswordError] = useState(null);
	const [emailError, SetEmailError] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	let { room_key } = useParams();

	const changeLogin = (event) => {
		if (event.target.value.length > MaxEmailLength) {
			SetEmailError(<span><FormattedMessage id="cantbelonger"/> {MaxEmailLength}</span>)
		} else {
			SetEmailError(null)
		}
		setLogin(event.target.value);
	}
	const changePassword = (event) => {
		if (event.target.value.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
		} else {
			SetPasswordError(null)
		}
		setPassword(event.target.value);
	}
	const doLogin = ()=>{
		if (login.length > MaxEmailLength) {
			SetEmailError(<span><FormattedMessage id="cantbelonger"/> {MaxEmailLength}</span>)
			return
		} else {
			SetEmailError(null)
		}
		if (password.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return
		} else {
			SetPasswordError(null)
		}
        AuthService.loginPassword(login, password).then(response=>{
            if (response.data.Success == SuccessMessage) {
                localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
                localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
                localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
                setAuthorized({Authorized: true})
				props.onClose()
				navigate("/games")
            }
        }).catch((err)=>{
			console.log("ASASAS ERROR", err.response)
			if (err.response.data.ErrorCode === 4) {
				SetPasswordError(<FormattedMessage id="EmailOrPasswordIncorrect"/>)
			}
		})
	}

	const loginVK = ()=>{
		if (location.pathname.startsWith("/room/")) {
			localStorage.setItem('RegistrationRoom', room_key);
		}
		goToVKForCode(VKAppID)
	}
	const switchToForgotPassword = ()=>{
		setForgotPassword(true)
	}
    const recoverPassword = ()=>{
		AuthService.recoverPassword(login).then(response=>{
            if (response.data.Success == SuccessMessage) {
				setRecoverPasswordSent(true)
			}
		})
	}
	return (
		<Stack spacing={2} className="login-form">
			{!recoverPasswordSent && <TextField 
				id="name" 
				value={login}
				onChange={changeLogin}
				label={<FormattedMessage id="email" />}
				variant="outlined"
				helperText={emailError}
				error= {emailError != null}
			/>}

			{!forgotPassword && <TextField 
				id="password" 
				value={password}
				onChange={changePassword}
				label={<FormattedMessage id="password" />}
				type="password" 
				variant="outlined" 
				defaultValue="12345" 
				helperText={passwordError}
				error= {passwordError != null}
			/>}

			{recoverPasswordSent &&
				<FormattedMessage id="recover_password_sent" />
			}

			{!forgotPassword && <Link href="#" underline="hover" onClick={switchToForgotPassword}>
				<FormattedMessage id="forgot_password" />
			</Link>}
			
			{!forgotPassword && <Button variant="contained" size="large" onClick={doLogin}>
				{<FormattedMessage id="login" />}
			</Button>}

			<Box
				style={{
					display: "block",
					padding: "1px 1px",
					border: "1px solid #eee",
					marginLeft: "auto",
					marginRight: "auto",
					textAlign: "center",
				}} 
			>
				<Typography variant='subtitle1'>
					<FormattedMessage id="PressingEnterYouAccept" />
					<a href="/user_agreement" target="_blank"><FormattedMessage id="userAgreement"/> </a> <FormattedMessage id="andProvisions"/>
					<a href="/privacy_policy" target="_blank"><FormattedMessage id="privaciesPolicy"/></a>
				</Typography>
			</Box>

			<Button variant="contained" onClick={loginVK} startIcon={<img style={{width:"30px"}} src="/auth/vk_auth.png" />}>
				<FormattedMessage id="login_vk" />
			</Button>

			{forgotPassword && !recoverPasswordSent && <Button variant="contained" size="large" onClick={recoverPassword}>
				{<FormattedMessage id="recover_password" />}
			</Button>}		
		</Stack>
	);
};

export default LogInForm;