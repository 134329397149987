
export const isMobile = window.matchMedia("(max-width: 899px)").matches



export function makeString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const ifLow10 = (n) => {
    if (n < 10) {
        return "0"+n
    }
    return n
}
export const timeOf = (t)=>{
    let d = new Date(t)
    return ifLow10(d.getHours()) + ":" + ifLow10(d.getMinutes()) + ":" + ifLow10(d.getSeconds())
}

export const isIterable = function(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

export const shuffle = function(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }  

export const numOfWidthSlots = (slotWidth)=>{
    let nums = Math.floor(window.innerWidth / slotWidth);
    return nums
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
 export const stringAvatar = (name)=> {
      let initials = name.split(' ');
  
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials.length > 1 
              ? `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}` 
              : name[0].toUpperCase(),
    };
  }

export const lightLogout = ()=> {
  localStorage.removeItem("TetatetSettings");
  localStorage.removeItem("AuthToken");
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("DeviceKey");
  window.location.href = "/"
}


