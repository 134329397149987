import { Button, Container, Modal, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import LeaderboardPage from "./LeaderboardPage/LeaderboardPage"
import {FormattedMessage} from "react-intl";
import {CurrentLeaderboard} from "./atoms"
import {useRecoilState,} from 'recoil';
import {Authorized} from "../user/atoms/Auth"


const LeaderboardsSchema = [
  {
    game_name: "SlyBalabama",
    game_id: 2, 
    leaderboards: [
      {
        Name: "SlyBalabama",
        Type: "Num"
      }
    ]
  },
  {
    game_name: "BlocksChallenge",
    game_id: 3, 
    leaderboards: [
      {
        Name: "time_mode_three_to_three",
        Type: "Num"
      },
      {
        Name: "time_mode_double_chrest",
        Type: "Num"
      },
      {
        Name: "time_mode_single_chrest",
        Type: "Num"
      },
      {
        Name: "number_mode_three_to_three",
        Type: "Time"
      },
      {
        Name: "number_mode_double_chrest",
        Type: "Time"
      },
      {
        Name: "number_mode_single_chrest",
        Type: "Time"
      },
      {
        Name: "infinite_mode_three_to_three",
        Type: "Num"
      },
      {
        Name: "infinite_mode_double_chrest",
        Type: "Num"
      },
      {
        Name: "infinite_mode_single_chrest",
        Type: "Num"
      }
    ]
  }
]



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`ratings-tabpanel-${index}`}
        aria-labelledby={`ratings-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `ratings-tab-${index}`,
      'aria-controls': `ratings-tabpanel-${index}`,
    };
  }

const Leaderboards = () => {
  const [currentLeaderboard, setCurrentLeaderboard] = useRecoilState(CurrentLeaderboard);
  const [authorized, setAuthorized] = useRecoilState(Authorized);


    const [tabValue, setTabValue] = useState(0);
    const [subTabValue, setSubTabValue] = useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
    setCurrentLeaderboard({GameID: LeaderboardsSchema[newValue].game_id, TechName: LeaderboardsSchema[newValue].leaderboards[0].Name,})
    setSubTabValue(0)
	};

  const handleSubTabChange = (event, newValue) => {    
    setCurrentLeaderboard({GameID: LeaderboardsSchema[tabValue].game_id, TechName: LeaderboardsSchema[tabValue].leaderboards[newValue].Name,})
    setSubTabValue(newValue)
	};

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{width: "100%"}}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                   {LeaderboardsSchema.map((item, index) => (
                      <Tab label={<FormattedMessage id={item.game_name} />} {...a11yProps(index)} />
                   ))}
                </Tabs>
            </Box>
            {LeaderboardsSchema.map((item, index) => (
              item.leaderboards.length == 1 ?
                <TabPanel value={tabValue} index={0}>
                  <LeaderboardPage techName={item.leaderboards[0].Name} gameID={item.game_id} type={item.leaderboards[0].Type}></LeaderboardPage>                            
                </TabPanel>
              :
                <TabPanel value={tabValue} index={1}>
                    <Box
                        sx={{
                            minHeight: '100vh',
                            bgcolor: 'background.default',
                            color: 'text.primary',
                        }}
                    >
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{width: "100%"}}>
                              <Tabs value={subTabValue} onChange={handleSubTabChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                                  {
                                    item.leaderboards.map((leaderboard, l_index) => (
                                      <Tab label={<FormattedMessage id={leaderboard.Name} />} {...a11yProps({l_index})} />
                                    ))
                                  }
                              </Tabs>
                              {
                                    item.leaderboards.map((leaderboard, l_index) => (
                                      <TabPanel value={subTabValue} index={l_index}>
                                              <LeaderboardPage techName={leaderboard.Name} gameID={item.game_id} type={leaderboard.Type}></LeaderboardPage>                            
                                      </TabPanel>
                                    ))
                                }
                          </Box>

                    </Box>
                </TabPanel>                                   
            ))}                                 
        </Box>
    )
}

{/* <TabPanel value={tabValue} index={0}>
                <LeaderboardPage techName="SlyBalabama" gameID={2}></LeaderboardPage>                            
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <LeaderboardPage gameID={1}></LeaderboardPage>                                
            </TabPanel>  */}

export default Leaderboards;
