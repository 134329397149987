import {
    useRecoilState,
} from 'recoil';
import {SiteWSOpened, SiteWS, SiteWSRestartTimeout, AllExperience, AddPointsNumber, UnviewedInvitations} from "./atoms"
import TokenService from "../services/auth/TokenService"
import Api from "../services/Api";
import React, {useState, useEffect} from 'react';
import {Authorized} from "../user/atoms/Auth"
import {BracketsMatches} from "../tournaments/atoms"

let ws = null

export const sendMsgSite = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, props, reconnect) {
    let {wsOpened, siteWSRestartTimeout, allExperience} = atomValues
    let {setWSOpened, setSiteWS, setSiteWSRestartTimeout, setAllExperience,
        setAddPointsNumber, setUnviewedInvitations, setBracketsMatches} = atomSets

    if (reconnect) {
        ws = new WebSocket(props.apiUrl)
        setSiteWS({ws: ws})
    }
    if (ws == null) {
        return
    }

    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Site WebSocket Site ControllerClient Connected');
        console.log("ASASAS ControllerClient Connected")
        sendMsgSite(ws, 'InitRequest', {'SessionID': TokenService.getLocalAccessToken(), 'GameName': ""})
    };

    ws.onclose = () => {
        console.log("ASASAS ControllerClient OnClose")

        if (siteWSRestartTimeout.timeout != 0) {
            clearInterval(siteWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, props, true);
        }, 2000);
        setSiteWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        console.log("ControllerClient HaveMessage", msg)

        switch (msg.Type) {
            case "UnviewedInvitations":
                console.log("UnviewedInvitations", msg)
                processUnviewedInvitations(msg, setUnviewedInvitations)
                break
            case "TournamentBracket":
                setBracketsMatches({matches: msg.Payload.Matches})
                break
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    if (Api.lastRefreshToken != TokenService.getLocalRefreshToken()) {
                        var refreshDate = localStorage.getItem('refreshDate')
                        if (refreshDate != null) {
                            if ((Math.floor(Date.now() / 1000)) - parseInt(refreshDate) < 3) {
                                setTimeout(()=>{
                                    ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GameName': ""}}));
                                }, 3000)
                                return
                            }
                        }
                        localStorage.setItem('refreshDate', (Math.floor(Date.now() / 1000)))
                        Api.post("/auth/refreshToken", {
                            RefreshToken: TokenService.getLocalRefreshToken(),
                        }).then(response=> {
                            TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                            localStorage.removeItem('refreshDate')
                            console.log("ASASAS ref WS REMOVE refresh")
                            ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GameName': ""}}));
                        }).catch((err) => {
                            TokenService.anotherRefreshErrorCheck()
                            localStorage.removeItem('refreshDate')
                            ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GameName': ""}}));
                        });
                    } else {
                        console.log("ASASAS lastRefreshToken = ", Api.lastRefreshToken)
                        setTimeout(()=>{
                            ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GameName': ""}}));
                        }, 1000);
                    }

                }               
                break         
        }
    }
}

function SiteController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(SiteWSOpened); 
    const [siteWSRestartTimeout, setSiteWSRestartTimeout]  = useRecoilState(SiteWSRestartTimeout);
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);


    const [authorized, setAuthorized] = useRecoilState(Authorized);

    const [allExperience, setAllExperience] = useRecoilState(AllExperience)
    const [addPointsNumber, setAddPointsNumber] = useRecoilState(AddPointsNumber)
    const [unviewedInvitations, setUnviewedInvitations] = useRecoilState(UnviewedInvitations)
    const [bracketsMatches, setBracketsMatches]  = useRecoilState(BracketsMatches);

    useEffect(()=> {
        // Empty SessionID means unauthorized user. Not empty - means authorized
        sendMsgSite(siteWS.ws, "InitRequest", {'SessionID': TokenService.getLocalAccessToken(), 'GameName': "",})
    }, [authorized]);

    useEffect(()=> {
        ws = new WebSocket(props.apiUrl)
        setSiteWS({ws: ws}) // , TokenService.getLocalAccessToken()
    }, []);

    useEffect(()=> {
        connect({wsOpened, siteWSRestartTimeout, allExperience,}, {setWSOpened, setSiteWS, setSiteWSRestartTimeout,  
             setAllExperience, setAddPointsNumber, setUnviewedInvitations, setBracketsMatches}, props, false)
    }, [allExperience,]);

    return (
        <span>
        </span>
    )
}

export default SiteController


const processAllExperienceState = function(msg, allExperience, setAllExperience) {
    setAllExperience(msg.Payload)
}

const processAddExperience = function(msg, setAddPointsNumber) {
    setAddPointsNumber(msg.Payload)
}

const processUnviewedInvitations = function(msg, setUnviewedInvitations) {
    setUnviewedInvitations({Invitations: msg.Payload.Invitations})
}