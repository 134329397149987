import {
    useRecoilState,
  } from 'recoil';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import AuthService from '../../../services/auth/AuthService';
import { SuccessMessage, MaxNickNameLength, MaxEmailLength, MaxPasswordLength, VKAppID, DefaultLang} from '../../../constants';
import { SmartCaptcha } from '@yandex/smart-captcha';
import {ErrorBoundary} from 'react-error-boundary'
import {vkRegisterRedirectUrl} from "../../../environment"
import {checkForMate} from "../../../utils/TextFilters"
import { DatePicker } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import moment from "moment"
import {removeTimezoneOffsetMomentJS} from "../../../utils/DateUtils"

export const goToVKForCode = (clientID)=>{
    window.location.replace('https://oauth.vk.com/authorize?client_id='+clientID+"&display=page&redirect_uri="+vkRegisterRedirectUrl+"&response_type=code&v=5.131");
}

const RegistrationForm = (props) => {
	const [birthDay, setBirthDay] = useState(moment());

	const [nickname, setNickname] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [checkLinkToEnd, setCheckLinkToEnd] = useState(false);
	const [password2Error, SetPassword2Error] = useState(null);
	const [passwordError, SetPasswordError] = useState(null);
	const [emailError, SetEmailError] = useState(null);
	const [nicknameError, SetNicknameError] = useState(null);
	const [token, setToken] = useState('');
	const [byEmail, setByEmail] = useState(false);
	const [youAreTooYoung, setYouAreTooYoung] = useState(false);

	const changeNickname = (event) => {
		if (event.target.value.length > MaxNickNameLength) {
			SetNicknameError(<span><FormattedMessage id="cantbelonger"/> {MaxNickNameLength}</span>)
			return	
		} else {
			SetNicknameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			SetNicknameError(<span><FormattedMessage id="cant_use_mate"/></span>)
			return	
		} else {
			SetNicknameError(null)
		}
		if (event.target.value.indexOf(' ') >= 0) {
			SetNicknameError(<span><FormattedMessage id="cant_use_spaces"/></span>)
			return		
		} else {
			SetNicknameError(null)
		}
		setNickname(event.target.value);
	}
	const changeEmail = (event) => {
		if (event.target.value.length > MaxEmailLength) {
			SetEmailError(<span><FormattedMessage id="cantbelonger"/> {MaxEmailLength}</span>)
			return	
		} else {
			SetEmailError(null)
		}
		setEmail(event.target.value);
	}
	const changePassword = (event) => {
		if (event.target.value.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return	
		} else {
			SetPasswordError(null)
		}
		setPassword(event.target.value);
	}
	const changePassword2 = (event) => {
		if (event.target.value.length > MaxPasswordLength) {
			SetPassword2Error(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return	
		} else {
			SetPassword2Error(null)
		}
		setPassword2(event.target.value);
	}
	const errorFallback = ()=>{
		setToken("asdasd")
	}
	const registerByEmail = ()=>{
		setByEmail(true)
	}

	const register = ()=>{
		if (nickname === "") {
			SetNicknameError(<FormattedMessage id="СantEmptyNickname" />)
			return
		}
		if (token == "") {
			SetPassword2Error(<FormattedMessage id="notARobotCheck" />)
			return
		}
		if (nickname.length > MaxNickNameLength) {
			SetNicknameError(<span><FormattedMessage id="cantbelonger"/> {MaxNickNameLength}</span>)
			return
		}
		if (password.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return
		}
		if (password2.length > MaxPasswordLength) {
			SetPassword2Error(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return
		}
		if (email.length > MaxEmailLength) {
			SetEmailError(<span><FormattedMessage id="cantbelonger"/> {MaxEmailLength}</span>)
			return
		}
		if (password == password2) {
			AuthService.register(nickname, email, password, birthDay).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setCheckLinkToEnd(true)
				}
			}).catch((err)=>{
				if (err.response.data.ErrorCode == 3) {
					SetNicknameError(<span><FormattedMessage id="alreadytaken"/></span>)
					return
				}
				if (err.response.data.ErrorCode == 2) {
					SetEmailError(<span><FormattedMessage id="alreadytaken"/></span>)
					return
				}
				if (err.response.data.ErrorCode == 1201) {
					SetEmailError(<span><FormattedMessage id="wrong_email_format"/></span>)
				}
			});
		} else {
			SetPassword2Error(<FormattedMessage id="notMatchingPasswords" />)
		}
	}

	const registerVK = ()=>{
		goToVKForCode(VKAppID)
	}
	const ErrorFallback = ({error, resetErrorBoundary}) => {
		setToken("dwqdqw")
		return (
		  <span role="alert">
		  </span>
		)
	}

	if (checkLinkToEnd) {
		return (
			<Stack spacing={2} className="registration-form">
				<Typography variant='body1'>
					<FormattedMessage id="ToEndRegisterCheckEmail" />
				</Typography>
			</Stack>
		)
	}
	const captchaStyle = {
        width: "300px",
        height: "100px",
    }

	if (youAreTooYoung) {
		return(
			<Stack spacing={2} className="registration-form" >
				<Typography variant='h6'>
					<FormattedMessage id="you_are_too_young_come_back_older" />
				</Typography>
			</Stack>
		)
	}

	return (
		<Stack spacing={2} className="registration-form" >
			{byEmail && <>
			<TextField 
				id="nickname" 
				value={nickname}
				onChange={changeNickname}
				label={<FormattedMessage id="nickname" />} 
				variant="outlined" 
				helperText={nicknameError}
				error= {nicknameError != null}
			/>

			<TextField 
				id="email" 
				value={email}
				onChange={changeEmail}
				label={<FormattedMessage id="email" />} 
				variant="outlined" 
				helperText={emailError}
				error= {emailError != null}
			/>

			<TextField 
				id="password"
				value={password}
				onChange={changePassword}
				label={<FormattedMessage id="password" />} 
				type="password"
				variant="outlined"
				helperText={passwordError}
				error= {passwordError != null} 
			/>	

			<TextField 
				id="password2" 
				value={password2}
				onChange={changePassword2}
				label={<FormattedMessage id="repeat_password" />}
				type="password"
				variant="outlined"
				helperText={password2Error}
				error= {password2Error != null}
			/>
			
			{false && <DatePicker
					label={<FormattedMessage id="birth_day" />}
					name="date"
					format="DD.MM.YYYY"
					dayOfWeekFormatter={(day) => `${day}`}
			value={birthDay} onChange={(newValue) => setBirthDay(removeTimezoneOffsetMomentJS(newValue))}
			localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}  />}

			<Box
				style={{
					display: "block",
					padding: "15px 20px",
					border: "1px solid #eee",
					marginLeft: "auto",
					marginRight: "auto",
					textAlign: "center",
				}} 
			>
				<ErrorBoundary
        			FallbackComponent={ErrorFallback}
        			onReset={() => {
					// reset the state of your app so the error doesn't happen again
					}}>
                    <div style={captchaStyle}><SmartCaptcha style={captchaStyle} sitekey="ysc1_JkO7esvn8KYP0iHSaxiYS6SfIurNUHliQpA3iVdk7845b8dc" onSuccess={setToken} />  </div>
        		</ErrorBoundary>
			</Box>

			</>}

			<Box
				style={{
					display: "block",
					padding: "1px 1px",
					border: "1px solid #eee",
					marginLeft: "auto",
					marginRight: "auto",
					textAlign: "center",
				}} 
			>
				<Typography variant='subtitle1'>
					<FormattedMessage id="PressingRegisterYouAccept" />
					<a href="/user_agreement" target="_blank"><FormattedMessage id="userAgreement"/> </a> <FormattedMessage id="andProvisions"/>
					<a href="/privacy_policy" target="_blank"><FormattedMessage id="privaciesPolicy"/></a>
				</Typography>
			</Box>
			
			{!byEmail ? <>
			<Button variant="contained" size="large" onClick={registerByEmail} disabled={nicknameError != null || emailError != null || passwordError != null}>
				<FormattedMessage id="register_by_email" />
			</Button>

			<Button variant="outlined" onClick={registerVK} startIcon={<img style={{width:"30px"}} src="/auth/vk_auth.png" />}>
				<FormattedMessage id="sign_up_vk" />
			</Button></> : 
					<Button variant="contained" size="large" onClick={register} disabled={nicknameError != null || emailError != null || passwordError != null}>
						<FormattedMessage id="sign_up" />
					</Button>			
			}
		</Stack>
	);
};

export default RegistrationForm;