



export const requestFullScreen = function() {
    var doc = window.document;
    var requestFullScreen = doc.requestFullscreen || doc.webkitRequestFullscreen || doc.mozRequestFullScreen || doc.msRequestFullscreen;
    if (requestFullScreen != null) {
        requestFullScreen.call()
    }
}