
import ReactGodot from "../react-godot/GodotReact.tsx"
import {useParams, useNavigate} from "react-router-dom"







const ShowGame = (props) => {
    let { gameName } = useParams();

    if (props.current) {
        localStorage.setItem(gameName+"_opponent", "NicknameTest");
    } else {
        localStorage.removeItem(gameName+"_opponent");
    }
    document.body.requestFullscreen()

    var GODOT_CONFIG = {}
    switch(gameName) {
        case "SlyBalabama":
            GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/SlyBalabama/SlyBalabama","experimentalVK":false,"fileSizes":{"SlyBalabama.pck":5218976,"SlyBalabama.wasm.gz":13790961},"focusCanvas":true,"gdnativeLibs":[]};
            break;
        case "Space1vs1v1":
            GODOT_CONFIG = {"args":[],"canvasResizePolicy": isMobile ? 2 : 1,"executable":"/games_content/Space1vs1v1/Space1vs1v1","experimentalVK":false,"fileSizes":{"Space1vs1v1.pck":4295248,"Space1vs1v1.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};
            break;
        case "BlocksChallenge":        
            GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/BlocksChallenge/BlocksChallenge","experimentalVK":false,"fileSizes":{"BlocksChallenge.pck":652832,"BlocksChallenge.wasm":13790961},"focusCanvas":true,"gdnativeLibs":[]};
            break;
    }

    return(
        <div key={"godotplaydiv"} style={{position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000"}}><ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} is_gz={false}/></div>
    )
}

export default ShowGame;