import { Button, Stack, TextField } from '@mui/material';
import React, {useState} from 'react';
import UserService from "../services/user/UserService";
import { SuccessMessage, MaxNickNameLength, DefaultLang } from '../constants';
import {useRecoilState,} from 'recoil';
import {NickNameModal, UserInfoAtom} from "../user/atoms/UserInfo"
import {FormattedMessage} from "react-intl";
import {checkForMate} from "../utils/TextFilters"


const SetNickNameModalPage = (props) => {
	const [nickname, setNickname] = useState("");
	const [nicknameError, SetNicknameError] = useState(null);

	const [nickNameModal, setNickNameModal] = useRecoilState(NickNameModal)
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);


	const changeNickname = (event) => {
		if (event.target.value.length > MaxNickNameLength) {
			SetNicknameError(<span><FormattedMessage id="cantbelonger"/> {MaxNickNameLength}</span>)
		} else {
			SetNicknameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			SetNicknameError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			SetNicknameError(null)
		}
		if (event.target.value.indexOf(' ') >= 0) {
			SetNicknameError(<span><FormattedMessage id="cant_use_spaces"/></span>)		
		} else {
			SetNicknameError(null)
		}
		setNickname(event.target.value);
	}

    const setNicknameClick = ()=>{
		if (nickname === "") {
			SetNicknameError(<FormattedMessage id="СantEmptyNickname" />)
			return
		}
		if (nickname.length > MaxNickNameLength) {
			SetNicknameError(<span><FormattedMessage id="cantbelonger"/> {MaxNickNameLength}</span>)
			return
		}
		UserService.setNickname(nickname).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setNickNameModal({Open: false})
				setUserInfo({UserInfo: {...userInfo, Nickname: nickname}})
			}
		}).catch((err)=>{
            if (err.response.data.ErrorCode == 2) {
				SetNicknameError(<span><FormattedMessage id="alreadytaken"/></span>)
				return
			}
		})
    }

    return (
		<Stack spacing={2} className="login-form">
			<TextField 
				id="name" 
				value={nickname}
				onChange={changeNickname}
				label={<FormattedMessage id="nickname" />}
				variant="outlined"
				helperText={nicknameError}
				error= {nicknameError != null}
			/>

            <Button variant="contained" size="large" onClick={setNicknameClick}>
				{<FormattedMessage id="set_nickname" />}
			</Button>
		</Stack>            

    )
}

export default SetNickNameModalPage;