import { LOCALES } from '../locales';

export default {
  [LOCALES.RUSSIAN]: {
    is_visible_others: 'Видно другим?',
    games: `Игры`,
    rooms: `Комнаты`,
    articles: `Статьи`,
    login: `Вход`,
    registration: `Регистрация`,
    about: `О себе`,
    name: `Имя`,
    surname: `Фамилия`,
    phone: `Телефон`,
    country: `Страна`,
    region: `Регион`,
    city: `Город`,
    change_password: `Изменить пароль`,
    cancel: `Отменить`,
    save: `Сохранить`,
    username: `Имя пользователя`,
    password: `Пароль`,
    repeat_password: `Повторите пароль`,
    invalid_password: `Неверный пароль`,
    sign_up: `Зарегистрироваться`,
    email: `Почта`,
    nickname: `Никнейм`,
    play: `Играть`,
    create_your_own: `Создать свою`,
    lobby_hackword: `Комнаты Hackword`,
    lobby_iamalive: `Комнаты Я живое?`,
    lobby_generalize: `Комнаты Обобщение`, 
    hackword: `Hackword`,
    who_am_i: `Я живое?`,
    for_their_own: `Для своих`,
    everyone_over_here: `Все сюда!`,
    creating_game: `Создание игры`,
    name_game: `Название игры`,
    number_players: `Количество игроков`,
    max_number_of_players_is: `Максимальное количество игроков`,
    max_number_of_users_is: `Максимальное количество пользователей`,
    spectators_are_possible: `Пользователи, которые не являются игроками, становятся наблюдателями`,
    you_are_already_in_another_room: `Вы уже в другой комнате`,
    go_to_that_room: `Перейти в ту комнату`,
    first_game_will_be: `Первая игра будет`,
    hackword_name: `Hackword`,
    iamalive_name: `Я живое?`,
    generalize_name: `Обобщение`,
    can_hear_everyone: `Слышно всех и всегда`,
    just_your_team: `или только свою команду`,
    age_restrictions: `18+ (мат, пошлости)`,
    closed: `Закрытая`,
    create: `Создать`,
    teams: `Команды`,
    score: `Счет`,
    set_of_words: `Набор слов`,    
    sec: `сек`,
    time_for_round: `Время на раунд`,
    words_to_victory: `Слов для победы`,
    settings: `Настройки`,
    easy: `Легкие`,
    complex: `Сложные`,
    professions: `Профессии`,
    animals: `Животные`,
    places_on_earth: `Места на Земле`,
    number_of_teams: `Количество команд`,
    start_over: `Начать заново`,
    shuffle_players: `Перемешать игроков`,
    ready: `Готов`,
    round: `Раунд`,
    round_words: `Слова раунда`,
    you_won: `Вы победили`,
    your_team: `Ваша команда`,
    your_place: `Ваше место:`,
    exit: `Выйти`,
    another_one: `Еще одну`,
    create_room: `Создать комнату`,
    casual_interlocutors: `Случайные собеседники`,
    creating_room: `Создание комнаты`,
    room_name: `Название комнаты`,
    number_participants: `Количество участников`,
    tag_text: `Текст тэга`,
    add: `Добавить`,
    your_gender: `Ваш пол`,
    gender_interlocutor: `Пол собеседника`,
    your_age: `Ваш возраст`,
    age_interlocutor: `Возраст собеседника`,
    search: `Искать`,
    male: `М`,
    female: `Ж`,
    no_matter: `Не важно`,
    young: `Молод`,
    mature: `Зрел`,
    your_account_has_been_blocked: `Ваш аккаунт заблокирован по причине`,
    inappropriate_behavior: `неподобаемое поведение`,
    you_can_protest: `Вы можете опротестовать это в чате. Просто опишите ситуацию как её видите вы.`,
    account_will_be_unblocked: `Аккаунт будет разблокирован`,
    by_popularity: `По популярности`,
    by_time: `По времени`,
    cycle: `Цикл`,
    gender: `Пол`,
    age: `Возраст`,
    youth: `молодость`,
    next: `Следующий`,
    create_community: `Создать сообщество`,
    my_communities: `Мои сообщества`,
    my_events: `Мои события`,
    communities: `Сообщества`,
    creating_community: `Создание сообщества`,
    type: `Тип`,
    title: `Название`,
    upload_logo: `Загрузить эмблему`,
    accept_everyone: `Принимать всех подряд`,
    description: `Описание`,
    date_and_time: `Дата и время`,
    view_room: `Вид комнаты`,
    view: `Вид`,
    creating_event: `Создание события`,
    back_to_events: `Обратно к списку событий`,
    join: `Присоединиться`,
    chat_events: `Чат события`,
    account: `аккаунт`,
    your_tariff: `Ваш тариф`,
    still_valid: `Действует еще`,
    subscription: `Подписка`,

    // Self but formatted
    register_by_email: "Зарегистрироваться по почте",
    is_recruiting: "Идет набор",
    already_recruited: "Уже набрано",
    set_birth_day: "Установить день рождения",
    birth_day: "День рождения",
    you_are_too_young_come_back_older: "Вы слишком молоды, приходите когда вам будет 18",
    you_need_to_be_18_plus: "Возрастное ограничение: 18+",
    only_one_time_to_do_it: "Это потребуется только один раз",
    you_are_too_young: "Вы слишком молоды",
    requires_password: "Требуется пароль",
    wrong_password: "Неверный пароль",
    game_panel: "Панель игры",
    admin_panel: "Панель админа",
    your_notes: "Ваши заметки",
    just_stop_picking_players: "Остановить набор игроков",
    appoint: "Назначить",
    choosing_word: "Выбор слова",
    for_whom_we_choose: "Кому выбирают",
    who_choose: "Кто выбирает",
    playing_now: "Идет игра",
    change: "Изменить",
    to_change_word_do_this: "Чтобы изменить загаданное слово наведите на него курсор и нажмите ИЗМЕНИТЬ",
    not_hear_anyone: "никого не слышит и мы его",
    change_own_word: "изменить свое слово",
    players_is_not_picking: "Набор игроков прекращен",
    club: "Клуб",
    community: "Сообщество",
    clan: "Клан",
    cartel: "Картель",
    team: "Команда",
    company: "Компания",
    max_number_participants: "Максимальное количество участников",
    talks: "Общение",
    iamalive: "Я живое?",
    made_request: "Заявка отправлена",
    decline_request: "Отменить запрос",
    you_are_member: "Вы состоите",
    you_are_admin: "Вы админ",
    you_are_owner: "Вы владелец",
    joined_unjoin: "Вы участвуете (перестать)",
    edit: "Изменить",
    editing_community: "Редактирование сообщества",
    willbe_comein_event_button: "Здесь будет кнопка ВОЙТИ, когда событие начнется",
    comein: "Войти",
    dota2_heroes: "Герои Dota2",
    mixup_entered_players: "Замешать зашедших игроков",
    sign_up_vk: "Зарегистрироваться через VK",
    alreadytaken: "Уже занят",
    login_vk: "Войти через VK",
    set_nickname: "Установить никнейм",
    days: "д.",
    minutes: "мин.",
    hours: "ч.",
    thankyou: "Спасибо",
    cant_create_more_communities: "Нельзя владеть больше чем 3 сообществами",
    forgot_password: "Забыли пароль?",
    PressingRegisterYouAccept: "Нажимая «Зарегистрироваться», вы подтверждаете, что прочли и принимаете условия ",
    PressingEnterYouAccept: "Нажимая «Войти», вы подтверждаете, что прочли и принимаете условия ",
    userAgreement: "пользовательского соглашения",
    andProvisions: " и положения ",
    privaciesPolicy: "политики конфиденциальности",
    notMatchingPasswords: "Пароли не совпадают",
    notARobotCheck: "Подтвердите, что вы не робот",
    СantEmptyNickname: "Никнейм не может быть пустым",
    Saved: "Сохранено",
    imageIsTooBigMoreThen3Mb: "Файл слишком большой (>3 мегабайт)",
    ToEndRegisterCheckEmail: "Для завершения регистрации перейдите по ссылке в письме, которое мы вам отправили.",
    profile: "Профиль",
    PasswordChanged: "Пароль изменен",
    Exit: "Выйти",
    NowPassword: "Пароль сейчас",
    NewPassword: "Новый пароль",
    OneMoreNewPassword: "Еще раз новый пароль",
    Change: "Изменить",
    cantbelonger: "не может быть длиннее",
    cantbeempty: "не может быть пустым",
    EmailOrPasswordIncorrect: "Неверный логин или пароль",
    PasswordIsNotTrue: "Неправильный пароль",

    // Game Names
    Space1vs1v1: "1 на 1 в Космосе",
    SlyBalabama: "Хитрец Балабама",
    BlocksChallenge: "Повелитель ветров",

    time_mode_three_to_three: 'Собери больше (3x3)',
    time_mode_double_chrest: 'Собери больше (двойной крест)',
    time_mode_single_chrest: 'Собери больше (тонкий крест)',
    number_mode_three_to_three: 'Собери быстрее (3x3)',
    number_mode_double_chrest: 'Собери быстрее (двойной крест)',
    number_mode_single_chrest: 'Собери быстрее (тонкий крест)',
    infinite_mode_three_to_three: 'Бесконечно (3x3)',
    infinite_mode_double_chrest: 'Бесконечно (двойной крест)',
    infinite_mode_single_chrest: 'Бесконечно (тонкий крест)',
    

    find_opponent_by_nickname: "Найти оппонента по никнейму",
    no_options_opponent: "Игроков с таким никнеймом не найдено",
    make_invitation: "Отправить приглашение",
    play_with_concrete_opponent: "Играть с конкретным оппонентом",
    play: "Играть",
    invitation_made: "Приглашение отправлено",
    invitation_already_made: "Приглашение уже отправлено",
    cant_use_mate: "Нельзя использовать матерные слова",
    cant_use_spaces: "Нельзя использовать пробелы",

    invited_you: "Тебя пригласил ",
    to_play: " поиграть в ",
    accept: "Принять",

    leaderboards: "Рейтинги",
    tournaments: "Турниры",

    players: "Игроки",
    brackets: "Сетка",
    regulations: "Правила",

    everyday_sly_balabama: "Ежедневный Хитрец Балабама",
    everyday_blocks_challenge: "Ежедневный Повелитель ветров",

    joined_successfully: "Теперь ты участвуешь в турнире",
    you_registered_to_tournament: "Ты зарегистрировался в турнире",
    you_participating_in_tournament: "Ты участвуешь в турнире",
    joined_tournament: "Присоединился к турниру",
    signed_in_tournament: "Подтвердил участие",
    status: "Статус",
    nobody_participating_for_now: "Пока никто не присоединился",
    tournament_will_start: "Турнир начнется ",
    time_left: "до начала осталось",
    to_participate_sign_in: "Для участия подтвердите свой статус/заявку с",
    till: "до",
    here_on_tournament_page: "здесь на странице турнира",
    commit_participation: "Подтвердить участие",
    more_then_day: " > чем день",
    exit_from_game: "выйти",
    has_no_brackets: "Сетка еще не сформирована",
    havenoanything: "Пользователь ничего не указал",
    rating_points: "Баллы в рейтингах",
    tournament_achievements_count: "Количество трофеев",
    has_no_trophies: "Трофеев еще нет",

    turn_device: "Поверните устройство",
    game_for_horizontal_orientation: "Игра для горизонтальной ориентации",

    authorize_to_watch_rating: "Авторизуйтесь, чтобы видеть рейтинги",
    authorize_to_watch_tournament: "Авторизуйcя, чтобы увидеть подробности",

    only_starting: "Мы только начинаем, поэтому пока только 2 игры. Но какие..."
  },
};