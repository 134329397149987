
import { Box, Grid, Typography, } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import {useNavigate, useLocation} from "react-router-dom"

import {Authorized} from "../user/atoms/Auth"
import {useRecoilState,} from 'recoil';
import {OpenAuth} from "../layout/atoms"



const games_data = [
    {
        name_id: "SlyBalabama",
        cover: "/games_content/SlyBalabama/cover.png",
    },
    {
        name_id: "BlocksChallenge",
        cover: "/games_content/BlocksChallenge/cover.png",
    },
    // {
    //     name_id: "Space1vs1v1",
    //     cover: "/games_content/Space1vs1v1/cover.png",
    // }
]









const Games = () => {
    const navigate = useNavigate();

    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authOpen, setAuthOpen] = useRecoilState(OpenAuth)


    const goToGame = (gameName)=>{
        // if (!authorized.Authorized) {
        //     setAuthOpen(true)
        // } else {
        //     navigate("/game_page/"+gameName)
        // }
        navigate("/game_page/"+gameName)
    }
    useEffect(()=>{
        window.yaContextCb.push(() => {
            Ya.Context.AdvManager.render({
                "blockId": "R-A-11933797-1",
                "renderTo": "yandex_rtb_R-A-11933797-1"
            })
        })
    }, [])
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                justifyContent: 'center',
            }}

        >
            <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                width={{xs: "340px", sm: "700px", md: "700px"}}
                margin="auto"
                alignItems="center"
                justifyContent="center"
            >
                {games_data.map((item, index) => (
                    <Grid key={index} item xs={4} sm={4} md={6} style={{padding: "20px", cursor: "pointer"}} onClick={()=>{goToGame(item.name_id)}}>
                        <img src={item.cover} style={{width: "300px", borderRadius:"40px"}}></img>
                        <Typography variant='h6' style={{width: "300px", margin: "auto"}}><FormattedMessage id={item.name_id} /></Typography>
                    </Grid>
                ))}
            </Grid>

            <Typography variant='h5' margin={{sm: 0, md: 7}}><FormattedMessage id="only_starting" /></Typography>

            <div id="yandex_rtb_R-A-11933797-1"></div>
        </Box>
    )
}

export default Games;