import {
    atom,
} from 'recoil';


export const CurrentLeaderboard = atom({
    key: 'CurrentLeaderboard',
    default: {
        GameID: 2,
        TechName: "SlyBalabama",
    }
})