
import AuthService from "../../services/auth/AuthService";
import {makeString} from "../../utils/common"
import {Authorized,} from '../../user/atoms/Auth'
import {useNavigate} from "react-router-dom"
import {useParams} from "react-router-dom";
import {
    useRecoilState,
} from 'recoil';
import {SuccessMessage, EndSaltLength,} from "../../constants";
import React, { useEffect } from 'react';


function CheckMail() {
    let { token } = useParams();
    
    const [authorized, setAuthorized] = useRecoilState(Authorized);

    const navigate = useNavigate();
    useEffect(() => {
        AuthService.loginCheckEmail(token).then(response=>{
            console.log("response", response)

            if (response.data.Success == SuccessMessage) {
                localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
                localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
                localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
                setAuthorized({Authorized: true})
                navigate("/")
            }
        })
    }, []);
    return (
        <div></div>
    )
}

export default CheckMail; 