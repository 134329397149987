
import { Box, Grid, Button } from '@mui/material';
import ReactGodot from "../../react-godot/GodotReact.tsx"
import { ErrorBoundary } from 'react-error-boundary'
import InvitePanel from "../InvitePanel.jsx"
import {useParams, useNavigate} from "react-router-dom";
import {useRecoilState,} from 'recoil';
import {PlayMobile} from "../atoms/Common.js"
import {isMobile} from "../../utils/common.js"
import React, { useMemo, useState, useEffect, useRef } from 'react';
import {requestFullScreen} from "../../utils/CrossBrowserUtils.js"
import {FormattedMessage} from "react-intl";

const GameName = "SlyBalabama"

const SlyBalabama = () => {
    const GODOT_CONFIG = {"args":[],"canvasResizePolicy": 2,"executable":"/games_content/SlyBalabama/SlyBalabama","experimentalVK":false,"fileSizes":{"SlyBalabama.mcs.gz":5218976,"SlyBalabama.gz":13790961},"focusCanvas":true,"gdnativeLibs":[],
      "mainPack": "/games_content/SlyBalabama/SlyBalabama.mcs.gz",
      "extraPacks": ["/games_content/SlyBalabama/SlyBalabama2.mcs.gz"]
    };

    // let authToken = localStorage.getItem('AuthToken');
    // if (authToken === null) {
    //     location.href = "/games"
    // }

    const [playMobile, setPlayMobile] = useRecoilState(PlayMobile)
    requestFullScreen()


    let { inviting } = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        if (inviting) {
            setPlayMobile(true)
        }
    }, [inviting])

    const playWithOpponent = ()=>{
        setPlayMobile(true)
    }
    const justPlay = ()=>{
        setPlayMobile(true)
    }
    const closeMobileGame = ()=>{
        location.href = "/games"
    }
    
    // if (playMobile) {
    //     return (<div key={"godotplaydiv"} style={{position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000"}}><div style={{position:"fixed", top: "7px", right: "7px", zIndex: 999999}} onClick={closeMobileGame}><img style={{width: "20px",}} src="/controls/close.png"/></div><ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG}  resize={true} /></div>)
    // }

    return (<div key={"godotplaydiv"} style={{position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "#000000"}}><Button size="small" variant="outlined" style={{position:"fixed", top: "5px", right: "5px", zIndex: 999999, display: 'inline-block', padding:0, minHeight: 0, minWidth: 0,}} onClick={closeMobileGame}><FormattedMessage id="exit_from_game"/></Button>
                <ReactGodot key={"godotplay"} godotConfig={GODOT_CONFIG} totalSize={14551460}  resize={true} is_gz={true}  loadingBanner={"/games_content/LoadingBanners/SlyBalabama.png"}/>
            </div>)


    // return (
    //     <Box
    //         sx={{
    //             minHeight: '100vh',
    //             bgcolor: 'background.default',
    //             color: 'text.primary',
    //         }}
    //     >
    //         <Grid
    //             container
    //             columns={{ xs: 4, sm: 4, md: 4}}
    //             justifyContent="space-between"
    //             alignItems="center"
    //             sx={{pt: "20px",}}
    //         >
	// 			<Grid item xs={4} sm={4} md={4}>
    //                 <InvitePanel gameName={GameName} fullScreenGame={true} inviting={inviting} playWithOpponent={playWithOpponent} justPlay={justPlay}></InvitePanel>
    //             </Grid>
	// 			<Grid item xs={4} sm={4} md={4}>
                        
    //             </Grid>                
    //         </Grid>
    //     </Box>
    // );
}

export default SlyBalabama;