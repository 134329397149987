import Api from "../Api"


// type GetLeaderboard struct {
//     GameID    uint64
//     Page      int
//     Count     int
//     GetPlayer bool
// }

const getLeaderboard = async function(techName, gameID, page, count, getPlayer) {
    return await Api.post(`/leaderboards/getLeaderboard`, {TechName: techName, GameID: gameID, Page: page, Count: count, GetPlayer: getPlayer})
}

const getUnAuthLeaderboard = async function(techName, gameID, page, count, getPlayer) {
    return await Api.post(`/getUnAuthLeaderboard`, {TechName: techName, GameID: gameID, Page: page, Count: count, GetPlayer: getPlayer})
}


const LeaderboardsService = {
    getLeaderboard,
    getUnAuthLeaderboard,
};
  
export default LeaderboardsService;