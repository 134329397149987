
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { TextField, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import {FormattedMessage} from "react-intl";
import React, {useState, useEffect} from 'react';
import GameService from "../services/games/GamesService"
import {SuccessMessage, } from "../constants";
import { Box, Grid, } from '@mui/material';
import {isMobile} from "../utils/common.js"








const SearchOpponentField = (props)=>{
    const [findStr, setFindStr] = useState("")
    const [requestTimeout, setRequestTimeout] = useState(0)
    const [variants, setVariants] = useState([])
    const [choosedOption, setChoosedOption] = useState(undefined)

    useEffect(() => {
        var opponentName = localStorage.getItem(props.gameName+"_opponent")
        if (props.inviting) {
            localStorage.setItem(props.gameName+"_opponent", props.inviting)
        } else if (opponentName != null) {
            localStorage.removeItem(props.gameName+"_opponent")
        }
    }, [props.inviting])

    const requestNicknames = (findString)=>{
        GameService.getOpponentVariants(findString).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setVariants(response.data.Variants)
            }
        })
    }
    const onChangeFindStr = (event)=>{
        clearTimeout(requestTimeout)
        setRequestTimeout(setTimeout(()=>requestNicknames(event.target.value), 700))
		setFindStr(event.target.value);
    }

    const changeOption=(event, newValue, reason)=>{
        if (reason == "selectOption") {
            setChoosedOption(newValue)
            localStorage.setItem(props.gameName+"_opponent", newValue.Nickname);
            props.changeOption(newValue)
        } 
        if (reason == "clear") {
            setChoosedOption(undefined)
            props.changeOption(undefined)
            localStorage.removeItem(props.gameName+"_opponent")
        }
    }
    return (
        <Autocomplete
            options={variants}
            sx={{ width: isMobile ? "100%" : "400px", display: props.fullScreenGame ? "inline-block" : "block"}}
            onClose={(event, reason) => {
            }}
            getOptionLabel={(option) => {
                if (option) {
                    return option.Nickname
                }
                return findStr
            }}
            noOptionsText={<FormattedMessage id="no_options_opponent"/>}

            value={choosedOption}
            onChange={changeOption}

            renderInput={(params) => {
            return <Paper
                sx={{ p: '0px', display: 'flex', alignItems: 'center', width: isMobile ? "100%" : "400px", }}
            >
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
                {choosedOption != undefined &&                 
                <Box
                    component="span"
                    sx={{
                        width: 14,
                        height: 14,
                        flexShrink: 0,
                        borderRadius: '300px',
                        mr: 1,
                        mt: '2px',
                    }}
                    style={{ backgroundColor: choosedOption.Online ? "#118a31" : "#8f8f8f" }}
                />
                }
                <TextField  {...params} label={<FormattedMessage id="find_opponent_by_nickname"/>} value={findStr} onChange={onChangeFindStr}/>
            </Paper>}}

            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Box
                        component="span"
                        sx={{
                            width: 14,
                            height: 14,
                            flexShrink: 0,
                            borderRadius: '300px',
                            mr: 1,
                            mt: '2px',
                        }}
                        style={{ backgroundColor: option.Online ? "#118a31" : "#8f8f8f" }}
                    />
                    <Box
                    sx={{
                        flexGrow: 1,
                    }}
                    >
                        {option.Nickname}
                    </Box>
              </li>
            )}

        />

    )
}

export default SearchOpponentField;