/**
 * @function ReactGodot
 */

import "./styles.css"

import * as React from "react"

import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Button, Typography } from '@mui/material';

import AsyncLoading from "./AsyncLoading.tsx"
import ReactCanvas from "./ReactCanvas.tsx"
import {useRecoilState,} from 'recoil';
import {GodotEngine} from "./atoms.tsx"
import {FormattedMessage} from 'react-intl'
import {isMobile} from "../utils/common.js"

const useScript = (url, engine, onLoad) => {
  useEffect(() => {
    if (engine != null) {
      console.log("ASASAS engine is NOT NULL")
      return
    }
    const script = document.createElement("script")

    script.src = url
    script.async = true
    script.onload = onLoad

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])
}

export type ReactGodotProps = {
  resize?: boolean
  width?: number
  height?: number
  params?: any
  godotConfig: any
  is_gz: boolean
  totalSize?: number
  loadingBanner?: string
}

var loadingStart:any = null
var loadingFinished:any = null

const ReactGodot: FunctionComponent<ReactGodotProps> = props => {
  let scriptUrl = "/games_content/mcs_start_script.js"
  const { resize = false, width, height, params, godotConfig, is_gz, totalSize, loadingBanner } = props
  const outerRef = useRef<HTMLDivElement>(null)
  const [engine, setEngine] = useState(null)
  const [dimensions, setDimensions] = useState([width, height])
  const [isLandscape, setIsLandscape] = useState(false)
  const [stillPlay, setStillPlay] = useState(false)


  if (is_gz) {
    scriptUrl = "/games_content/mcs_start_script_gz.js"
  }

  useEffect(() => {
    if (engine != null) {
      console.log("ASASAS engine is NOT NULL")
      loadingFinished = new Date()
      console.log("ASASAS TIMETOLOAD = ", loadingFinished - loadingStart)
      return
    }
    loadingStart = new Date()
    const script = document.createElement("script")

    script.src = scriptUrl
    script.async = true
    script.onload = () => {
      console.log("ASASAS OnLoadScript")
      const scope = window as any
      //if (engine == null) {
        console.log("ASASAS engine is NULL---")
        setEngine(() => scope.Engine)
      // } else {
      //   console.log("ASASAS engine is NOTNOTNOTNOTNOT NULL")
  
      // }
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [scriptUrl])

  const updateOrientation = () => {
    if (window.screen.orientation.type.includes("landscape")) {
      setIsLandscape(true)
    } else {
      setIsLandscape(false)
    }
  }

  useEffect(() => {
    if (window.screen.orientation.type.includes("landscape")) {
      setIsLandscape(true)
    } else {
      setIsLandscape(false)
    }
    window.addEventListener(
      'orientationchange',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'orientationchange',
        updateOrientation
      )
    }
  }, [])


  useEffect(() => {
    if (resize && outerRef.current) {
      setDimensions([
        outerRef.current.clientWidth,
        outerRef.current.clientHeight
      ])
    }
  }, [resize, outerRef.current])

  var showTurnDevice = !stillPlay && isMobile && !isLandscape
  const stillPlayClick = ()=>{
    setStillPlay(true)
  }

  return (
    <div id="wrap" ref={outerRef}>


      <AsyncLoading loadingBanner={loadingBanner}>
      {
        engine && (
          <ReactCanvas 
            engine={engine}          
            width={dimensions[0]}
            height={dimensions[1]}
            params={params}
            godotConfig={godotConfig}
            loadStarted={loadingStart}
            totalSize={totalSize}
          />
        )}
        <div style={{width: "100%", height: "100%", backgroundColor: "#626262", position: "fixed", top: 0, left: 0, zIndex: 9999, display: showTurnDevice ? "block" : "none"}} onClick={(event)=>{event.stopPropagation()}}>
          <img src="/hints/TurnPhone.png" style={{width: "100%"}}>

          </img>
          <div style={{color: "#ffffff", position: "absolute", top: "67%", left: "50%", width: "100%", transform: "translate(-50%, -50%)"}}>
            <Typography variant="h4" align="center" >
                <FormattedMessage id="turn_device"/>
            </Typography><br/>
            <Typography variant="h6" align="center" >
                <FormattedMessage id="game_for_horizontal_orientation"/>
            </Typography>
          </div>
          <Button variant="contained" style={{position: "fixed", bottom:"20px", left: "10%", width: "80%"}} onClick={stillPlayClick}><FormattedMessage id="play"/></Button>
        </div>
      </AsyncLoading>

    </div>
  )
}
// engine={engine}
export default ReactGodot