import React, {useState, useEffect} from 'react';
import {useSearchParams, useNavigate} from "react-router-dom"
import AuthService from '../../services/auth/AuthService';
import {SuccessMessage, EndSaltLength} from "../../constants";
import {
    useRecoilState,
  } from 'recoil';
  import {Authorized} from '../atoms/Auth'
  import {makeString} from "../../utils/common"


const GetVKCodeToRegisterLogin = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	const [authorized, setAuthorized] = useRecoilState(Authorized);

    useEffect(() => {
        AuthService.registerLoginVK(searchParams.get("code")).then((response)=>{
			if (response.data.Success == SuccessMessage) {
                localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
                localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
                localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
                setAuthorized({Authorized: true})
                var regRoom = localStorage.getItem("RegistrationRoom")
                if (regRoom != null) {
                    localStorage.removeItem("RegistrationRoom")
                    navigate("/room/"+regRoom)
                } else {
                    navigate("/games")
                }
            }
        }).catch((err)=>{
            navigate("/")
		})
    }, [])

    return(<span></span>)
}

export default GetVKCodeToRegisterLogin;