


export const SuccessMessage = "Yeah"
export const EndSaltLength = 3
export const MaxAvatarSize = 3 * 1024 * 1024


export const MaxNickNameLength = 30
export const MaxPasswordLength = 50
export const MaxEmailLength = 64

export const MaxAboutLength = 500
export const MaxCountryLength = 50
export const MaxRegionLength = 50
export const MaxCityLength = 50
export const MaxTelephoneLength = 15
export const MaxNameLength = 50
export const MaxSurnameLength = 50


export const MaxCommunityNameLength = 50
export const MaxCommunityDescriptionLength = 300

export const MaxUserReportDescriptionLength = 500


export const VKAppID = 51904053

export const DefaultLang = "ru"