
import ReactMarkdown from 'react-markdown';
import React, { useState, useEffect } from 'react';
import remarkGfm from 'remark-gfm'
import { Container, Stack  } from '@mui/material';





const Regulations = (props) => {
    const [currentPage, setCurrentPage] = useState("")

    useEffect(()=>{
        if (RegulationsTextes[props.tournament.Name+"_ru"]) {
            setCurrentPage(RegulationsTextes[props.tournament.Name+"_ru"])
        }
    }, [props.tournament])
    return (
        <Container maxWidth="md">
            <Stack spacing={2} paddingY={4}>
                    <ReactMarkdown 	className="markdown"
                    remarkPlugins={[remarkGfm]}
						children={currentPage}
					/>
            </Stack>
		</Container>   
    )

}

export default Regulations;



const RegulationsTextes = {
    "everyday_sly_balabama_ru" : `
### Ежедневный турнир "Хитрец Балабама"

Турнир проходит каждый день. Для участия нужно присоединиться и потом за 15 минут до турнира подтвердить свой статус

Чтобы играть, нужно зайти в игру и вас автоматически перекинет в лобби турнира

Если соперник не появляется в течении 5 минут, вам засчитывается победа

Если соперник появился и вы оба не проводите игру в течении 10 минут с её начала, игра автоматически закрывается, победит тот кто прожал "Готов". 

Это сделано, чтобы все участники не ждали слишком долго и могли планировать свое время

Игры проводятся в формате БО1 по олимпийской системе

Все игры проходят на 1 карте

ЕСЛИ ЕСТЬ КАКИЕ-ЛИБО ВОПРОСЫ ПИШИТЕ В ОБЩЕМ ЧАТЕ ТЕЛЕГРАМ ИЛИ ВК

Победитель получает соответствующую ачивку на свою личную страницу
`,
"everyday_blocks_challenge_ru" : `
### Ежедневный турнир "Повелитель ветров"

Турнир проходит каждый день. Для участия нужно присоединиться и потом за 15 минут до турнира подтвердить свой статус

Чтобы играть, нужно зайти в игру и вас автоматически перекинет в лобби турнира

Если соперник не появляется в течении 5 минут, вам засчитывается победа

Если соперник появился и вы оба не проводите игру в течении 10 минут с её начала, игра автоматически закрывается, победит тот кто прожал "Готов". 

Это сделано, чтобы все участники не ждали слишком долго и могли планировать свое время

Игры проводятся в формате БО1 по олимпийской системе

Режим игры: собери больше за 3 минуты, фигуры 3x3

ЕСЛИ ЕСТЬ КАКИЕ-ЛИБО ВОПРОСЫ ПИШИТЕ В ОБЩЕМ ЧАТЕ ТЕЛЕГРАМ ИЛИ ВК

Победитель получает соответствующую ачивку на свою личную страницу
`,
}