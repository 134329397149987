import Api from "../Api"





const getTournaments = async function() {
    return await Api.post(`/getTournaments`, {})
}

const joinTournament = async function(tournamentID) {
    return await Api.post(`/tournaments/joinTournament`, {TournamentID: tournamentID})
}

const getPlayersState = async function(tournamentID) {
    return await Api.post(`/tournaments/getPlayersState`, {TournamentID: tournamentID})
}

const signIn = async function(tournamentID) {
    return await Api.post(`/tournaments/signIn`, {TournamentID: tournamentID})
}

const getBrackets = async function(tournamentID) {
    return await Api.post(`/tournaments/getBrackets`, {TournamentID: tournamentID})
}

const TournamentsService = {
    getTournaments,
    joinTournament,
    getPlayersState,
    signIn,
    getBrackets,
};
  
export default TournamentsService;