import * as React from "react"
import { PropsWithChildren } from 'react';

import { FunctionComponent, createContext, useContext, useReducer } from "react"

export type PackLoadingState = {
  mode: string
  initializing: boolean
  percent?: number
  msg?: string
}

export type PackLoadingAction = {
  msg?: string
  initialized?: boolean
  percent?: number
  mode: string
}

export type PackLoadingDispatch = (action: PackLoadingAction) => void

const packLoadingReducer = (
  state: PackLoadingState,
  action: PackLoadingAction
) => {
  if (!state.initializing) return state

  switch (action.mode) {
    case "progress":
    case "indeterminate":
    case "notice":
    case "hidden":
      break
    default:
      throw new Error("Invalid status mode")
  }

  const nextState = {
    mode: action.mode,
    msg: action.msg,
    percent: action.percent || 0,
    initializing: !action.initialized
  }

  return nextState
}

type LoadingProps = {
  notice?: string
  percent?: number
  indeterminate: boolean
  loadingBanner?: string
}

const LoadingContext = createContext<[PackLoadingState, PackLoadingDispatch]>([
  { mode: "", initializing: true },
  () => {}
])

export const useLoading = () => useContext(LoadingContext)

const Loading: FunctionComponent<LoadingProps> = ({
  notice,
  percent = 0,
  indeterminate = false,
  loadingBanner
}) => {
  return (
    <div id="status">
      {indeterminate ? (
        <div id="status-indeterminate" onContextMenu={e => e.preventDefault()}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          <>
            <img src={loadingBanner} style={{width: "100%", visibility: "visible", position: "absolute", top:"50%", transform: "translate(0, -50%)"}}></img>
          </>
          <div id="progress" onContextMenu={e => e.preventDefault()}>
            <div id="bar" style={{ width: percent + "%" }}>
              <div id="progress-value" ></div>
            </div>
          </div>

        </>
      )}
      {notice && (
        <div id="status-notice" className="godot">
          {notice}
        </div>
      )}
    </div>
  )
}


{/* <div id="status-progress" onContextMenu={e => e.preventDefault()}>
<div
  id="status-progress-inner"
  style={{ width: percent + "%" }}
></div>
</div> */}

interface Props {
  loadingBanner? : string
}

const AsyncLoading: React.FC<PropsWithChildren<Props>> = ({ children, loadingBanner }) => {
  const [loadingState, dispatchLoadingAction] = useReducer(packLoadingReducer, {
    mode: "indeterminate",
    initializing: true
  })

  return (
    <LoadingContext.Provider value={[loadingState, dispatchLoadingAction]}>
      {loadingState.mode !== "hidden" && (
        <Loading
          notice={loadingState.msg}
          percent={loadingState.percent}
          indeterminate={loadingState.mode === "indeterminate"}
          loadingBanner={loadingBanner}
        />
      )}
      {children}
    </LoadingContext.Provider>
  )
}

export default AsyncLoading