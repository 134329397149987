import Api from "../Api"
import StaticApi from "../StaticAPI"



const getUserInfo =  async function(token) {
    return await Api.post(`/users/getUserInfo`, {})
}

const getUserPersonal =  async function() {
  return await Api.post(`/users/getUserPersonal`, {})
}

const setUserPersonal =  async function(about, name, surname, phone, country, region, city, showAbout, showName, showSurname, showPhone, showCountry, showRegion, showCity) {
  return await Api.post(`/users/setUserPersonal`, {About: about, Name: name, Surname: surname, Phone: phone, Country: country, Region: region, City: city, 
    ShowAbout: showAbout,  ShowName: showName, ShowSurname: showSurname, ShowPhone: showPhone, ShowCountry: showCountry, ShowRegion: showRegion, ShowCity: showCity})
}

const changePassword = async function(lastPassword, newPassword) {
  return await Api.post(`/users/changePassword`, {LastPassword: lastPassword, NewPassword: newPassword})
}

const uploadAvatar = async function(avatarFile) {
  return await StaticApi.post(`/users/uploadAvatar`, avatarFile, {headers:{"Content-Type": "multipart/form-data"}})
}

const showUserInfo = async function(userID) {
  return await Api.post(`/users/showUserPersonal`, {UserID: userID})
}

const getUserPersonalNickname = async function(nickname) {
  return await Api.post(`/users/getUserPersonalNickname`, {Nickname: nickname})
}

const getUserInfoNickname = async function(nickname) {
  return await Api.post(`/users/getUserInfoNickname`, {Nickname: nickname})
}

const getExperienceLevels = async function(userID) {
  return await Api.post(`/users/getExperienceLevels`, {UserID: userID})
}

const getUserCommunities = async function(userID) {
  return await Api.post(`/users/getUserCommunities`, {UserID: userID})
}

const logout = async function(refreshToken, deviceKey) {
  return await Api.post(`/users/logout`, {RefreshToken: refreshToken, DeviceKey: deviceKey})
}

const buyPremiumMonth = async function() {
  return await Api.post(`/users/buyPremiumMonth`, {})
}

const setBirthDay = async function(birthDay) {
  return await Api.post(`/users/setBirthDay`, {BirthDay: birthDay})
}

const getMyNickname = function() {
  let myNickname = localStorage.getItem('my_nickname');
  if (myNickname === null) {
      return null
  }
  return myNickname;
}

const setNickname = async function(nickname) {
  return await Api.post(`/users/setNickname`, {Nickname: nickname})
}

const setShowState = async function(showState) {
  return await Api.post(`/users/setShowState`, {ShowState: showState})
}

const reportUser = async function(userID, description) {
  return await Api.post(`/users/reportUser`, {UserID: userID, Description: description})
}

const getUserLeaderboards = async function(userID) {
  return await Api.post(`/users/getUserLeaderboards`, {UserID: userID})
}

const getUserTournamentsAchievements = async function(userID) {
  return await Api.post(`/users/getTournamentAchievements`, {UserID: userID})
}

const UserService = {
    getUserInfo,
    changePassword,
    uploadAvatar,
    showUserInfo,
    getUserPersonal,
    setUserPersonal,
    logout,
    buyPremiumMonth,
    setBirthDay,
    setNickname,
    setShowState,
    getUserPersonalNickname,
    getUserInfoNickname,
    getExperienceLevels,
    getUserCommunities,
    reportUser,
    getMyNickname,
    getUserLeaderboards,
    getUserTournamentsAchievements,
  };
  
export default UserService;