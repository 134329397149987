
import { Box } from '@mui/system';
import LeaderboardEntry from "./LeaderboardEntry"
import { Stack, Pagination, useTheme} from "@mui/material";
import LeaderboardsService from "../../services/leaderboards/LeaderboardsService"
import UserService from "../../services/user/UserService"
import React, { useState, useEffect } from 'react';
import { SuccessMessage } from '../../constants';
import {Authorized,} from "../../user/atoms/Auth"
import {useRecoilState,} from 'recoil';
import {CurrentLeaderboard} from "../atoms"
import {millisToTime} from "../../utils/DateUtils"

const NumberOfLeaderboardsOnPage = 10


const LeaderboardPage = (props) => {
    const [currentLeaderboard, setCurrentLeaderboard] = useRecoilState(CurrentLeaderboard);

    const theme = useTheme();

    const myNickname =  UserService.getMyNickname()

    const [leaderboardEntries, setLeaderboardEntries] = useState([]);
    const [maxScore, setMaxScore] = useState(1);
    const [deviderIndex, setDeviderIndex] = useState(100000);


    const [pagesCount, setPagesCount] = useState(1);
	const [page, setPage] = useState(1);

    const [meUser, setMeUser] = useState(null);

    const [authorized, setAuthorized] = useRecoilState(Authorized);

    useEffect(() => {
        if (currentLeaderboard.GameID != props.gameID || currentLeaderboard.TechName != props.techName) {
            return
        }
        const setEntries = (entries, count)=>{
            if (entries.length > 0) {
                setMaxScore(entries[0].Score)
            }
            if (props.type == "Num") {
                setLeaderboardEntries(entries)
            } else {
                var _entries = []
                for (var i = 0; i < entries.length; i++) {
                    entries[i].Score = millisToTime(entries[i].Score)
                }
                setLeaderboardEntries(entries)
            }
            let pagesCount = (count - count % NumberOfLeaderboardsOnPage) / NumberOfLeaderboardsOnPage
            if (count % NumberOfLeaderboardsOnPage > 0) {
                pagesCount = pagesCount + 1
            }
            setPagesCount(pagesCount)
            for (var i = 0; i < entries.length; i++) {
                if (entries[i].ID == 0) {
                    setDeviderIndex(i)
                }
                if (myNickname === entries[i].Nickname) {
                    setMeUser(entries[i])
                }
            }
        }
        if (authorized.Authorized) {
            LeaderboardsService.getLeaderboard(props.techName, props.gameID, page, NumberOfLeaderboardsOnPage, true).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setEntries(response.data.Entries, response.data.NumberOfEntries)
                }
            })
        } else {
            LeaderboardsService.getUnAuthLeaderboard(props.techName, props.gameID, page, NumberOfLeaderboardsOnPage, false).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setEntries(response.data.Entries, response.data.NumberOfEntries)
                }
            })
        }
    }, [page, authorized, currentLeaderboard])
    
	const pagesChange = (e, v)=>{
		setPage(v)
	}
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                width: "100%",
                pt: 1,
            }}
        >
            <Stack direction="column" alignItems="left" style={{width: "100%"}}>
                {leaderboardEntries.map((item, index) => (
                    index < deviderIndex ? <LeaderboardEntry
                            key={"leaderboardentry"+index}
                            place={item.Place}
                            nickname={item.Nickname}
                            userID={item.PlayerID}
                            score={item.Score}
                            myplace={meUser != null ? meUser.Place : 0}
                            divider={true}
                            percents={Math.round(item.Score / maxScore * 100)}
                    /> : null
				))}
            </Stack>
            <Stack spacing={4} marginY={4}>
            <Box style={{ display: "flex", justifyContent: 'center'}}>
                {pagesCount > 1 ? <Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/> : null}
            </Box>
            </Stack>
            {meUser != null && authorized.Authorized ? <div style={{padding: "25px 20px 10px 20px", position: "fixed", bottom: "0px", left: "0px", width: "100%", backgroundColor: theme.palette.mode == "light" ? "#D0EBFC" : "#3f2f9c"}}>
                    <LeaderboardEntry
                        key={"laederboardentryme"}
                        place={meUser.Place}
                        nickname={meUser.Nickname}
                        userID={meUser.PlayerID}
                        score={meUser.Score}
                        myplace={meUser != null ? meUser.Place : 0}
                        divider={false}
                        percents={Math.round(meUser.Score / maxScore * 100)}
                    />
            </div> : null}
        </Box>
    )
}

export default LeaderboardPage;
